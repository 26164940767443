/* eslint-disable eqeqeq */
import React from "react";
import notes_icon from "../assets/images/Vector.png";
import linkifyHtml from "linkify-html"; // import ReactDOMServer from 'react-dom-server';
import moment from "moment";
// import Editable from "react-x-editable";
import { confirmAlert } from "react-confirm-alert"; // Import
import { NoteModal } from "../ReusabelComponents/NoteModal";
import { IsTextHTML } from "../Helpers/Constants";
const copy = require("clipboard-copy");
// import ContentEditable from 'react-contenteditable';
class EditableTextView extends React.PureComponent {
  positions = [];
  constructor(props) {
    //
    //
    super(props);
    if (props.row.index % 20 < 5) {
      this.positions.push("left top");
    } else {
      this.positions.push("left top");
      this.positions.push("left bottom");
    }

    let temp = props.row.value;
    if (props.columnName === "urgency") {
      temp = temp.replace(".0", "");
    } else if (props.columnName == "phone_number") {
      //Phone No format fixed
      var cellNo;
      cellNo = props.row.value;
      var cellNo = cellNo.replace(/\D/g, "");
      var index = 3;
      var result = cellNo.slice(0, index) + "-" + cellNo.slice(index);
      index = 7;
      cellNo = result.slice(0, index) + "-" + result.slice(index);
      temp = cellNo;
    } else if (props.columnName == "notes") {
      //
      if (props.row.value.length > 0) {
        temp = props.row.value[props.row.value.length - 1].note; //.toString().split('\n').join(',');
      }
    }
    this.text = temp;
    if (this.text == null) {
    }

    this.state = {
      row: props.row,
      isInEditMode: false,
    };
  }
  // componentDidMount() {
  //   this.props.updateDataByID()
  // }
  componentDidUpdate() {
    if (this.props.columnName == "notes") {
      if (this.props.row.value.length > 0) {
        this.text = this.props.row.value[this.props.row.value.length - 1].note;
      }
    }
  }
  getLastNoteDate = (timeLine) => {
    let NotesList = [];
    NotesList = timeLine.filter((item) => {
      return item.item_type == "note";
    });
    let noteTime = NotesList[0]?.created_at;
    return noteTime;
  };

  confirmAction = (title, message, yesCallback, noCallback) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => yesCallback(),
        },
        {
          label: "No",
          onClick: () => noCallback(),
        },
      ],
    });
  };

  submitValue = (value) => {
    console.log("inputValues", value);
    this.text = value;
    this.props.handleSubmit(
      this.props.columnName,
      this.props.row.original[this.props.columnName],
      value,
      this.props.row.original._id
    );
    // this.setState({
    //   isInEditMode: false,
    // });
  };
  onCancelEditModal = () => {
    this.setState({
      isInEditMode: false,
    });
    console.log("close click");
    // this.props.refreshData()
  };
  onPopupOpen = () => {
    this.setState({ isInEditMode: true });
    this.setState({ copied: false });
  };
  render() {
    if (this.props.columnName == "notes") {
      // console.log("notes", this.props.row.value)
      if (this.props.row.value.length > 0) {
        let temp = this.props.row.value[this.props.row.value.length - 1].note;
        this.text = temp;
      }
    }
    let a = 0;
    let displayText = "";
    displayText = this.text;
    if (displayText == null) {
      displayText = "";
    }
    // if ((this.props.selectedColumn == 3 || this.props.selectedColumn == 1) && this.props.columnName == "notes") {
    if (
      displayText == null ||
      displayText == "" ||
      this.props.row.value.length < 1
    ) {
      displayText = "";
    }
    // }
    // else if (displayText == null || displayText == "") {
    //   displayText = "Empty";
    // }
    const linkiFytext = linkifyHtml(displayText, {
      target: {
        url: "_blank",
        email: null,
      },
    });
    const isTextHTML = IsTextHTML(linkiFytext);
    return (
      <div
        // className={this.props.editableClassName}
        style={{
          overflow: "hidden",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          textOverflow: "ellipsis",
          position: "relative",
        }}
      >
        <div
          style={{
            // marginBottom: 8,
            marginRight: 10,
            width: 22,
            minWidth: 15,
            height: 22,
          }}
        >
          <img
            style={{ cursor: "pointer", width: 22, height: 22 }}
            src={notes_icon}
          />
        </div>
        <span
          style={{
            alignSelf: "center",
            color: "black",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            wordBreak: "break-all",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            paddingTop: displayText == "" ? 0 : 10,
            paddingLeft: 3,
          }}
        >
          {/* <span
            dangerouslySetInnerHTML={{ __html: displayText.toString() }}
            style={{
              display: "flex",
              width: 250,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              height: 25,
            }}></span> */}

          {isTextHTML ? (
            <span
              className='ulDiv'
              dangerouslySetInnerHTML={{ __html: linkiFytext }}
              style={{
                // width: 250,
                textOverflow: "ellipsis",
                overflow: "hidden",
                height: 25,
              }}
            ></span>
          ) : (
            <span
              style={{
                display: "flex",
                width: 250,
                textOverflow: "ellipsis",
                overflow: "hidden",
                height: 25,
              }}
              className='display-linebreak'
            >
              {linkiFytext}
            </span>
          )}

          <span
            style={{
              fontSize: 12,
              color: "GrayText",
              marginTop: -4,
            }}
          >
            {this.props.row?.original?.notes?.length > 0
              ? moment(
                  this.getLastNoteDate(this.props.row.original.time_line)
                ).format("MM-DD-YYYY, h:mm a")
              : null}
          </span>
        </span>
        {this.state.isInEditMode && ( //ma
          <NoteModal
            showAlert={this.props.showAlert}
            leadData={this.state.row}
            defaultValue={this.text}
            show={this.state.isInEditMode}
            submitValue={this.submitValue}
            onCancelEditModal={this.onCancelEditModal}
            allowToUpdate={this.props.allowToUpdate}
            updateDataByID={this.props.updateDataByID}
            users={this.props.users}
          />
        )}
        <button
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            position: "absolute",
            borderStyle: "none",
          }}
          onClick={() => this.setState({ isInEditMode: true })}
        ></button>
      </div>

      // </div>
      //   }
      // // position={this.positions}
      // >
      // </Popup>
    );
  }
}
export default EditableTextView;
