/* eslint-disable eqeqeq */
import React, { Component } from "react";
import ReactTable from "react-table";
import axios from "axios";
import "react-table/react-table.css";
import {
	baseURL,
	isValidPhoneNumber,
	isValidEmail,
	isValidName,
	capitalizeFirstLetter,
	capitalizeFirstLetteronFilter,
} from "../Helpers/Constants";
import { connect } from "react-redux";
import ActionsCell from "../Users/ActionsCell";
import plusIcon from "../assets/images/plus_icon.svg";
import { addUserData } from "../Store/actions";
import ColorPickerModal from "../ReusabelComponents/Modals/ColorPicker/ColorPicker";
class UsersScreen extends Component {
	state = {
		pageSize: 15,
		selectedRowIndex: -1,
		filteredData: [],
		isLoading: false,
		showColorPicker: false,
		color: "#EC1010",
		columnId: null,
		originalUser: null,
	};

	editAbleColumns = [];
	onEmptyUserAdded = (obj) => {
		this.selectedRowIndex = this.props.users.length;
		this.userData = obj;
		this.editAbleColumns = ["phone_number", "color"];
		if (this.userData._id == null) {
			this.editAbleColumns.push("name");
			this.editAbleColumns.push("user");
			this.editAbleColumns.push("nick_name");
		}
		this.setState({ editing: this.userData });
		this.originalData = { ...this.userData };
	};
	onColorChanged = (value, key, email = "") => {
		if (this.userData == null) {
			this.props.OnUpdateUserColor(email, value);
		}
	};
	onValueUpdated = async (value, key, email = "") => {
		if (this.userData == null) {
			//
			axios
				.post(
					baseURL() + "/api/v1/users/assign-colors",
					[
						{
							email: email,
							color: value,
						},
					],
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					}
				)
				.then((res) => {
					console.log("updating color succeeded");
					this.props.OnUpdateUserColor(email, value);
				})
				.catch((error) => {
					console.log("updating color failed");
				});
		} else {
			this.userData[key] = value;
		}
	};
	OnClickShowAddUserModal = () => {};
	editableColumnProps = {
		Cell: (props) => {
			const editing = this.state.editing === props.original;
			if (editing && this.editAbleColumns.indexOf(props.column.id) > -1) {
				//
				var placeHolder = "";
				let maxLength = "";
				switch (props.column.id.trim()) {
					case "name":
						placeHolder = "Name";
						maxLength = 20;

						break;
					case "phone_number":
						placeHolder = "Phone";
						maxLength = 22;

						break;
					case "user":
						placeHolder = "Email";

						break;
					case "nick_name":
						placeHolder = "Nick Name";

						break;
					default: {
						placeHolder = "Hello";
					}
				}
				return (
					<input
						style={{ width: "100%" }}
						onChange={(e) =>
							this.onValueUpdated(e.target.value, props.column.id)
						}
						type="text"
						autoFocus
						placeholder={placeHolder}
						maxLength={maxLength}
						defaultValue={props.original[props.column.id]}
					/>
				);
			}
			if (
				props.column.id == "name" &&
				props.original[props.column.id] == "button"
			) {
				return (
					<div
						style={{
							cursor: "pointer",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: 132,
							height: 30,
							border: "none",
							borderRadius: 6,
							background: "#F7F7FA",
						}}
						onClick={() => this.props.onAddNewUserButtonClick()}
					>
						<div
							style={{
								width: "90%",
								height: "100%",
								display: "flex",
								flexDirection: "row",
								justifyItems: "space-between",
								alignItems: "center",
							}}
						>
							<div
								style={{
									width: 10,
									fontSize: 10,
									color: "#8592AD",
								}}
							>
								<button
									// onClick={props.onEdit}
									style={{
										backgroundImage: `url(${plusIcon})`,
										width: 16,
										height: 16,
										border: "none",
										backgroundRepeat: "no-repeat",
										backgroundColor: "transparent",
										backgroundSize: "contain",
										cursor: "pointer",
										// marginLeft: -7,
										marginTop: 1,
									}}
								></button>
							</div>
							<div
								style={{
									fontSize: 10,
									color: "#8592AD",
									marginLeft: 14,
									fontFamily: "SF Pro Text Bold",
								}}
							>
								ADD NEW USER
							</div>
						</div>
					</div>
				);
			}
			return <td>{props.original[props.column.id]}</td>;
		},
	};
	colorPickerColoumProps = {
		Cell: (props) => {
			if (props.original.name == "button") {
				return null;
			}
			return (
				<div
					style={{
						width: "100%",
						height: "90%",
						backgroundColor: props.value,
						border: "none",
						borderRadius: "8px",
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.preventDefault();
						this.setState({
							showColorPicker: true,
							columnId: props.column.id,
							originalUser: props.original.user,
							color: props.value,
						});
					}}
				></div>
			);
		},
	};
	//ma
	getActionProps = (gridState, rowProps) =>
		(rowProps && {
			mode:
				this.state.editing === rowProps.original
					? "edit"
					: rowProps.original.name == "button"
					? "empty"
					: "view",
			actions: {
				onEdit: (index) => {
					this.selectedRowIndex = index;
					this.setState({ editing: rowProps.original });
					this.userData = rowProps.original;
					this.originalData = { ...rowProps.original };
					this.editAbleColumns = [
						"phone_number",
						"color",
						"nick_name",
					];
					if (this.userData._id == null) {
						this.editAbleColumns.push("name");
						this.editAbleColumns.push("user");
					}
					this.props.onEditClicked(index);
					this.props.allowToUpdate();
				},
				// onDelete: index => {
				// 	//
				// 	this.selectedRowIndex = index;
				// 	console.log('deleting user' + index);

				// 	this.props.confirmAction(
				// 		'Delete User',
				// 		'Are you sure you want to delete this user?',
				// 		() => {
				// 			console.log('in here')
				// 			this.props.deleteUser(this.selectedRowIndex, () => {
				// 				this.props.showAlert('User has been deleted.');
				// 			});
				// 		},
				// 		() => { }
				// 	);
				// },
				onDelete: (index) => {
					this.selectedRowIndex = index;
					console.log(this.props.users[index].user);
					// if (this.props.users[index]._id == null) {
					// 	this.props.deleteUser(this.selectedRowIndex, () => { });
					// } else {
					//
					this.props.confirmAction(
						"Delete User",
						"Are you sure you want to delete this user?",
						() => {
							let email = this.props.users[index].user;
							console.log(localStorage.getItem("email"));

							this.props.deleteUser(this.selectedRowIndex, () => {
								this.props.showAlert("User has been deleted.");
								if (email === localStorage.getItem("email")) {
									//
									this.props.showAlert(
										"User No Longer Available"
									);
									localStorage.clear();
									window.location.reload();
								}
							});
						},
						() => {}
					);
				},
				onCancel: () => {
					console.log("canceled");
					this.setState({ editing: null });
					if (this.userData._id != null) {
						console.log("in if");
						this.userData["name"] = this.originalData.name;
						this.userData["user"] = this.originalData.user;
						this.userData["phone_number"] =
							this.originalData.phone_number;
						this.userData["color"] = this.originalData.color;
						this.userData["nick_name"] =
							this.originalData.nick_name;

						this.setState({ editing: null });
						this.setState({ state: this.state });
					} else {
						console.log("in else");
						this.props.deleteUser(this.selectedRowIndex, () => {});
					}
				},
				onSave: (index) => {
					let indexOfSameName = -1;
					let indexOfSameEmail = -1;

					for (let i = 0; i < this.props.users.length; i++) {
						if (index != i) {
							if (
								this.props.users[i].name
									.toString()
									.toLowerCase() ===
								this.userData.name.toString().toLowerCase()
							) {
								indexOfSameName = 0;
								break;
							}
							if (
								this.props.users[i].user
									.toString()
									.toLowerCase() ===
								this.userData.user.toString().toLowerCase()
							) {
								indexOfSameEmail = 0;
								break;
							}
						}
					}
					if (!isValidName(this.userData.name)) {
						this.setState({ editing: null }, () => {
							this.setState({ editing: rowProps.original });
						});
						this.props.showAlert("Please enter a valid name");
					} else if (!isValidEmail(this.userData.user)) {
						this.setState({ editing: null }, () => {
							this.setState({ editing: rowProps.original });
						});
						this.props.showAlert("Please enter a valid email");
					} else if (
						!isValidPhoneNumber(this.userData.phone_number)
					) {
						this.setState({ editing: null }, () => {
							this.setState({ editing: rowProps.original });
						});
						this.props.showAlert(
							"Please enter a valid phone number"
						);
						// return
					} else if (indexOfSameName >= 0) {
						this.setState({ editing: null }, () => {
							this.setState({ editing: rowProps.original });
						});
						this.props.showAlert("Please enter a unique name");
					} else if (indexOfSameEmail >= 0) {
						this.setState({ editing: null }, () => {
							this.setState({ editing: rowProps.original });
						});
						this.props.showAlert("Please enter a unique email");
					} else {
						this.setState({ editing: null });

						// setting is developer for a new user to false
						this.userData.isDeveloper = false;
						this.props.updateUser(
							this.userData,
							this.userData._id == null,
							this.selectedRowIndex
						);
						this.selectedRowIndex = -10;
					}
				},
			},
		}) ||
		{};
	firstColumns = [
		{
			header: "",
			resizable: false,
			width: 52,
			maxWidth: 100,
			filterable: false,
		},

		{
			Header: "Name",
			accessor: "name",
			width: 350,
			maxWidth: 500,
			minWidth: 100,
			minResizeWidth: 100,
			...this.editableColumnProps,
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						//
						//   flag = !flag;
						//
						onChange(capitalizeFirstLetteronFilter(e.target.value));
					}}
				/>
			),
		},
		{
			Header: "Nick Name",
			accessor: "nick_name",
			width: 120,
			maxWidth: 150,
			minWidth: 100,
			minResizeWidth: 100,
			...this.editableColumnProps,
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						//
						//   flag = !flag;
						//
						onChange(capitalizeFirstLetteronFilter(e.target.value));
					}}
				/>
			),
		},
		{
			Header: "Email",
			accessor: "user",
			width: 320,
			maxWidth: 400,
			minWidth: 100,
			minResizeWidth: 100,
			...this.editableColumnProps,

			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						//
						//   flag = !flag;
						//
						onChange(e.target.value);
					}}
				/>
			),
		},
		{
			Header: "Phone",
			accessor: "phone_number",
			width: 220,
			maxWidth: 300,
			minWidth: 100,
			minResizeWidth: 100,
			...this.editableColumnProps,
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						//
						//   flag = !flag;
						//
						onChange(e.target.value);
					}}
				/>
			),
		},
		{
			Header: "Color",
			accessor: "color",
			width: 120,
			maxWidth: 200,
			minWidth: 100,
			minResizeWidth: 100,
			filterable: false,

			...this.colorPickerColoumProps,
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						//
						//   flag = !flag;
						//
						onChange(e.target.value);
					}}
				/>
			),
		},
		{
			Header: "",
			accessor: "",
			width: 200,
			maxWidth: 200,
			minWidth: 130,
			minResizeWidth: 130,
			filterable: false,
			getProps: this.getActionProps,
			Cell: ActionsCell,
		},
		// {
		//   header: "",
		//   minWidth: 50,
		//   filterable: false,
		// },
	];

	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.users = props.users;
	}
	showLoader = () => {
		this.props.allowToUpdate();
		this.setState({ isLoading: true });
	};
	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}
	componentDidMount() {
		//
		// this.setState({filteredData: this.props.leads});
	}

	handleSubmit = (values) => {
		//
	};

	render() {
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-12">
						<div
							style={{
								marginTop: 0,
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<form
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
								onSubmit={this.handleSubmit}
							>
								<ReactTable
									className="-striped -highlight userScreen"
									data={this.props.users}
									columns={
										localStorage.getItem(
											"secretUserEmail"
										) === localStorage.getItem("email")
											? this.firstColumns.filter(
													(column, index) =>
														index !==
														this.firstColumns
															.length -
															1
											  )
											: this.firstColumns
									}
									defaultPageSize={50}
									searching={true}
									sortable={false}
									filterable={true}
									minRows={0}
									PadRowComponent={<span>&nbsp;</span>}
									// loading={this.state.isLoading}
									showPagination={false}
									noDataText="No record found Data"
									// pageSize={this.state.pageSize}
									style={{
										width: "100%",
										height: this.props.tableHeight - 60,
									}}
									onPageSizeChange={(pageSize, pageIndex) => {
										this.props.allowToUpdate();
										this.setState({ pageSize: pageSize });
									}}
									onPageChange={(pageIndex) => {
										if (
											(pageIndex + 1) *
												this.state.pageSize >=
											this.props.users.length
										) {
											this.props.fetchMoreData(
												this.state.pageSize,
												pageIndex
											);
										}
									}}
									getTdProps={(state, rowInfo, column) => {
										return {
											onClick: (e) => {
												if (rowInfo != null) {
													this.selectedLead =
														rowInfo.original;
													this.selectedRowIndex =
														rowInfo.index;

													if (
														column.id !==
															"assigned_to" &&
														column.id !==
															"bid_status" &&
														column.id !==
															"service_fee"
													) {
													}
												}
											},
										};
									}}
									getTrProps={(
										state,
										rowInfo,
										column,
										instance
									) => {
										if (rowInfo != null) {
											return {
												onClick: (e) => {},
												style: {
													display: "flex",
													// background: rowInfo.original.lead_color,
													justifyContent: "center",
													alignItems: "center",
													height: 30,
												},
												data: this.props.users[
													rowInfo.index
												],
											};
										} else {
											return {};
										}
									}}
									onFilteredChange={() => {
										this.props.allowToUpdate();
										this.setState({ isLoading: false });
										console.log(
											"onFilteredChange callled ar"
										);
									}}
									ref={(r) => (this.reactTable = r)}
								/>
							</form>
						</div>
					</div>
				</div>
				<div
					className="row"
					style={{ height: 60, alignItems: "center" }}
				>
					<div
						className="col-md-8"
						style={{ position: "absolute", left: 46 }}
					>
						<div
							style={{
								cursor: "pointer",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: 132,
								height: 30,
								border: "none",
								borderRadius: 6,
								background: "#F7F7FA",
							}}
							onClick={() =>
								this.props.onAddNewUserButtonClick(
									this.onEmptyUserAdded
								)
							}
						>
							<div
								style={{
									width: "90%",
									height: "100%",
									display: "flex",
									flexDirection: "row",
									justifyItems: "space-between",
									alignItems: "center",
								}}
							>
								<div
									style={{
										width: 10,
										fontSize: 10,
										color: "#8592AD",
									}}
								>
									<button
										// onClick={props.onEdit}
										style={{
											backgroundImage: `url(${plusIcon})`,
											width: 16,
											height: 16,
											border: "none",
											backgroundRepeat: "no-repeat",
											backgroundColor: "transparent",
											backgroundSize: "contain",
											cursor: "pointer",
											// marginLeft: -7,
											marginTop: 1,
										}}
									></button>
								</div>
								<div
									style={{
										fontSize: 10,
										color: "#8592AD",
										marginLeft: 14,
										fontFamily: "SF Pro Text Bold",
									}}
								>
									ADD NEW USER
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.showColorPicker && (
					<ColorPickerModal
						show={this.state.showColorPicker}
						close={() => this.setState({ showColorPicker: false })}
						color={this.state.color}
						onChange={async (color) => {
							this.onColorChanged(
								color.hex,
								this.state.columnId,
								this.state.originalUser
							);
							await this.onValueUpdated(
								color.hex,
								this.state.columnId,
								this.state.originalUser
							);
							return true;
						}}
					/>
				)}
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		_userData: state._userData,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		addUserData: (templatesData) => dispatch(addUserData(templatesData)),
	};
};

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(UsersScreen);
// export default UsersScreen;
