import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import "./sanfrancisco-font.css";
import "./inter-font.css";
import "./gilroy-font.css";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
//localStorage.clear();

ReactDOM.render(
	<React.StrictMode>
		{/* <Provider store={store}> */} {/* Provide Redux store */}
		<Router>
			<App /> {/* Use App component directly */}
		</Router>
		{/* </Provider> */}
	</React.StrictMode>,
	document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
