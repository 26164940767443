/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import LeadsScreen from "../../components/pages/LeadsScreen";
import TemplateScreen from "../../components/pages/TemplateScreen";
import UsersScreen from "../../components/pages/UsersScreen";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import {
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	subWeeks,
	subMonths,
	subQuarters,
	endOfDay,
	startOfDay,
} from "date-fns";

import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
	FilterRemindersForUser,
	baseURL,
	capitalizeName,
	changeLeadData,
	dataChangeForLead,
	formatDate2,
	getReminderLead2,
	remindersUpdateLeads,
	secretUserCheck,
	secretUserCheck1,
	secretUserCredentials,
	secretUserLeadFilter,
	updateReminderLead,
	updateReminderLeadData,
	updateReminderLeadHotlist,
} from "../Helpers/Constants";
import moment from "moment";
import exportIcon from "../assets/images/export_icon.png";
import logoutIcon from "../assets/images/logout.svg";
import exportDownloadIcon from "../assets/images/download-2.png";
// import leadIcon from "../assets/images/leads-icon.svg";
import templateIcon from "../assets/images/templateIcon.svg";
import templateActiveIcon from "../assets/images/templateActiveIcon-newer.svg";
import leadsIcon from "../assets/images/leadsIcon.svg";
import hotlistIcon from "../assets/images/hotlistIcon.svg";
import hotlistIconSelected from "../assets/images/hotlistIcon-selected.svg";
import reminderIcon from "../assets/images/reminder-unset.png";
import reminderSetIcon from "../assets/images/clock.png";
import csvExportIcon from "../assets/images/Frame-2.png";
import csvDownloadIcon from "../assets/images/csv_download.png";
import crossIconX from "../assets/images/x-2.png";
import closeIcon from "../assets/images/close-icon.png";
import docIcon from "../assets/images/doc-icon.png";
import { Button } from "@material-ui/core";

import leadActiveIcon from "../assets/images/lead-active-newer.svg";
// import leadActiveIcon from "../assets/images/leads-active-icon.svg";
import LoaderFile from "./LoaderFile";
import userIcon from "../assets/images/usericon_black.svg";
import userActiveIcon from "../assets/images/userActive.svg";
import dropdownIcon from "../assets/images/dropdown.svg";
import starIcon from "../assets/images/star-icon.svg";
import ReviewIcon from "../assets/images/ReviewIcon.png";
import refmessage from "../assets/images/ref-message.png";
import unstarIcon from "../assets/images/un-star-icon.svg";
import toggleNavbarIcon from "../assets/images/toggle-navbar.svg";
import usersIcon from "../assets/images/users-icon.svg";
import usersActiveIcon from "../assets/images/users-active-icon.svg";
// import logoIcon from "../assets/images/logo.svg";
import logoIcon from "../assets/images/omega-logo-blue.svg";
import analyticsIcon from "../assets/images/analytics-icon.svg";
import analyticsActiveIcon from "../assets/images/analytics-active-icon.svg";
import searchIcon from "../assets/images/search-icon.svg";
import searchActiveIcon from "../assets/images/search-active-icon.svg";

import { confirmAlert } from "react-confirm-alert"; // Import
// import AppSettings from ''
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./Home.css";
import SnoozeModal from "../ReusabelComponents/SnoozeModal";
import ColumnsChecklist from "../pages/ColumnsChecklist";
import { connect } from "react-redux";
import { onGetLeads } from "../Store/actions";
import AddNewLeadForm from "./AddNewLeadForm";
import SourceMapping from "./SourceMapping";
import AnalyticsScreen from "../pages/AnalyticsScreen";
import StarScreen from "../pages/StarScreen";
import RefReview from "../Leads/RefReview";
import {
	addUserData,
	addSelectedFilter,
	addSelectedSmsFilter,
} from "../Store/actions";
import Templates from "../pages/TemplatesScreen/Templates";
import SpotlightSearch from "../ReusabelComponents/Modals/SpotlightSearch/Spotlight";
import ReminderScreen from "../pages/ReminderScreen";
import FilterModal from "../ReusabelComponents/Modals/Filters/Filters";
import FilteredDataHeader from "./FilteredDataHeader";
import SearchHeader from "./SearchHeader";
import ExportLeadsModal from "./ExportLeadsModal";
import { fetchFilteredLeads } from "../../api/api";
const io = require("socket.io-client");
//const [flag, setFlag] = useState(); //refferal useState
class App extends Component {
	// eslint-disable-next-line no-useless-constructor
	selectionStrings =
		"lead_number utc_time_stamp name  email address phone_number svc_requested assigned_to bid_status service_fee lead_status is_complete archived actions starred_by_user_ids urgency email_auto_contact_count auto_contact_count auto_review_count auto_referral_count  harvestSMSSent source notes extra_links automatic_email_sent updated_at channel harvestEstimateID time_line total_calls_count sold_status channel_source email_selected_templates sms_selected_templates assignedSequence sequenceStatus totalTemplates nextTemplateNumber nextTemplate nextTemplateDate assignedSequenceSms sequenceStatusSms totalTemplatesSms nextTemplateNumberSms nextTemplateSms nextTemplateDateSms emailSequence smsSequence startTemplateDate startTemplateDateSms firstTemplateDate secondTemplateDate thirdTemplateDate firstTemplateDateSms secondTemplateDateSms thirdTemplateDateSms reminder temp_color category fakeEmailWarning";
	allAvailableColumns = [
		"name",
		"email",
		"address",
		"phone_number",
		"svc_requested",
		"assigned_to",
		"lead_status",
		"archived",
		"starred_by_user_ids",
		"auto_review_count",
		"auto_referral_count",
		"source",
		"notes",
		"extra_links",
		"updated_at",
	];
	initialCategories = [
		{
			name: "Assignee",
			subCategories: [],
		},
		{
			name: "Category",
			subCategories: [],
		},
		{
			name: "Timeframe",
			subCategories: [],
		},
		{
			name: "Hotlist",
			subCategories: [],
		},
	];
	leadsToFetchOneTime = 50;
	constructor(props) {
		super(props);
		// this.LeadsScreenReff = React.createRef(); // Create ref here
		this.headerRow = React.createRef();
		this.state = {
			fetchingLeads: false,
			filterModal: false,
			todayDate: "",
			itemskey: 0,
			allLeads: [],
			isLoggedIn: false,
			email: "",
			password: "",
			users: null,
			allUsersData: null,
			allSystemUsers: null,
			filteredData: [],
			starLeadsData: [],
			reminderLeadsData: [],
			columnsOrder: [
				"bid_status",
				"updated_at",
				"created_at",
				"utc_time_stamp",
				"name",
				"email",
				"address",
				"phone_number",
				"svc_requested",
				"assigned_to",
				"archived",
				"starred_by_user_ids",
				"auto_review_count",
				"auto_referral_count",
				"",
			],
			columnsSize: [],
			refreshPage: false,
			selectedTabIndex: 0,
			showExportCustomersView: false,
			showProgressIndicator: false,
			collapseIcon: exportIcon,
			secondTabText: "Users",
			firstTabText: "Leads",
			showAlert: false,
			showProgressAlert: false,
			alertMessage: "User has been deleted",
			isFollowUpEnabled: false,
			innerAvailableHeight: window.innerHeight,
			SelectedUser: null,
			highlightColor: "#ffff",
			headerCollapse: false,
			displayableColumns: [
				{
					key: "all_leads",
					name: "All Leads",
					tabIndex: 0,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						// 'lead_status',
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
				{
					key: "fresh_leads",
					name: "Fresh Leads",
					tabIndex: 2,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						"lead_status",
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
				{
					key: "ageing_leads",
					name: "Ageing Leads",
					tabIndex: 3,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						"lead_status",
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
				{
					key: "inprogress_leads",
					name: "In Progress Leads",
					tabIndex: 4,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						"lead_status",
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
				{
					key: "completed_leads",
					name: "Completed Leads",
					tabIndex: 5,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						"lead_status",
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
				{
					key: "reminder_leads",
					name: "Reminder Leads",
					tabIndex: 8,
					selectedColumns: [
						"utc_time_stamp",
						"name",
						"email",
						"address",
						"phone_number",
						"svc_requested",
						"assigned_to",
						"lead_status",
						"archived",
						"starred_by_user_ids",
						"auto_review_count",
						"auto_referral_count",
						"source",
						"notes",
						"extra_links",
						"automatic_email_sent",
						"updated_at",
					],
				},
			],
			analyticsData: [],
			locations: null,
			searchText: "",
			displaySearchBar: false,
			tempData: null,
			searchInputVal: "",
			searchFieldRef: null,
			version: 3.71,
			autofetch: 0,
			renderFilter: false,
			filteredLeadsData: [],
			filterModal: false,
			exportableData: "",
			CSVFileName: "",
			exportLeadModal: false,
			filtersCount: 0,
			loader: false,
			filterName: "",
			categories: this.initialCategories,
			isFilterAdded: false,
			autoFetchLead: false,
			searchedLeads:[],
			showSearchHeader:false
		};

		this.dataUpdated = true;
		// this.filtersCount = 0;
	}
	setLeadsScreenRef = (node) => {
		this.LeadsScreenRef = node;
	};

	clearCache = () => {
		localStorage.clear();
		this.clearCookies();
		// clear the cache
		this.emptyCache();
		// localStorage.setItem("version", packageJson.version);
	};

	componentDidMount() {
		// var localVer = JSON.parse(localStorage.getItem("version"));
		var localVer = this.state.version;

		console.log("localVer", localVer?.toFixed(2));
		axios
			.get(
				baseURL() +
					"/is-portal-new-version-available/" +
					`${localVer?.toFixed(2)}`,
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then((response) => {
				// Use arrow function here
				console.log(
					"version api response",
					response.data.data.current_version
				);
				if (response.status === 200) {
					if (response.data.data.is_new_version_available) {
						console.log(
							"New version vaialable",
							response.data.data.current_version
						);
						localStorage.clear();
						// clear the cache
						this.emptyCache(response.data.data.current_version);
					} else {
						console.log(
							"No new version available",
							response.data.data.current_version
						);
						// setTimeout(() => {
						// 	localStorage.setItem(
						// 		"version",
						// 		response.data.data.current_version
						// 	);
						// }, 200);
						this.setState({
							version: response.data.data.current_version,
						});
					}
				} else {
					console.log("Something went wrong");
				}
			})
			.catch(function (error) {
				console.log("VersionNumber.appVersion", error);
			});

		// const { location } = this.props;
		// const params = new URLSearchParams(location.search);

		const formatedDate = async () => {
			const today = await formatDate2(new Date());
			this.setState({ todayDate: today });
		};
		formatedDate();
		// check version from local storage
		// let localVersion = JSON.parse(localStorage.getItem("version"));
		// console.log(
		// 	"Version = " + localVersion + "  --  " + this.state.version
		// );

		// // if it is different than the app version, cleer the cache
		// if (localVersion == null || localVersion != this.state.version) {
		// 	this.clearCookies();
		// 	// clear the cache
		// 	this.emptyCache();
		// 	localStorage.setItem("version", JSON.stringify(this.state.version));
		// }

		this.setState({
			headerCollapse: localStorage.getItem("headercollapsed") == "1",
		});
		localStorage.getItem("columnsOrder") != null
			? localStorage.getItem("columnsOrder")
			: JSON.stringify(this.state.columnsOrder);

		this.setState({
			innerAvailableHeight: window.innerHeight - 60,
		});
		if (localStorage.getItem("displayableColumns") != null) {
			this.setState({
				displayableColumns: JSON.parse(
					localStorage.getItem("displayableColumns")
				),
			});
		}
		window.addEventListener("resize", () => {
			if (window.innerHeight !== this.state.innerAvailableHeight) {
				let temp = 0;
				let _temp = this.state.headerCollapse ? 10 : 60;
				this.allowToUpdate();
				this.setState({
					innerAvailableHeight: window.innerHeight - _temp - temp,
				});
			}
		});

		if (localStorage.getItem("email") == null) {
			this.onLogoutClick();
		}

		axios
			.get(baseURL() + "/api/v1/leads/sources", {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				},
			})
			.then((res) => {
				// this.originalLocations = res.data.sources;
				this.setState({
					locations: res.data.sources,
					isLoading: false,
				});
			})
			.catch((error) => {
				this.setState({ isLoading: false });
			});

		axios
			.get(baseURL() + "/api/v1/app-data", {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				},
			})
			.then((res) => {
				this.setState({
					isFollowUpEnabled: res.data.data.auto_followup_enabled,
				});
			})
			.catch((error) => {});

		// axios
		// 	.get(baseURL() + "/api/v1/users", {
		// 		headers: {
		// 			"x-access-token": localStorage.getItem("jwtToken"),
		// 			"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
		// 		},
		// 	})
		// 	.then((res) => {
		// 		setTimeout(() => {
		// 			var users = res.data.data;
		// 			var allFilterUser = secretUserCheck1(users);
		// 			this.setState({
		// 				users: this.getArrayofKey(allFilterUser),
		// 				allUsersData: allFilterUser,
		// 			});
		// 		}, 100);
		// 	})
		// 	.catch((error) => {});

		////
		axios
			.get(baseURL() + "/api/v1/users", {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				},
			})
			.then((res) => {
				const usersList = res.data.data;
				secretUserCredentials(usersList);
				var allFilterUser = secretUserCheck1(usersList);

				var users = this.getAllUserArrayofKey(usersList);
				var filterUser = secretUserCheck(users);
		
				this.setState({
					allSystemUsers: filterUser,
					users: this.getArrayofKey(allFilterUser),
					allUsersData: allFilterUser,
				});
				this.props.addUserData(users);
				// }, 8000);
			})
			.catch((error) => {
				console.log("Error in user fetching:", error);
			});

		////
		axios
			.post(
				baseURL() + "/api/v1/users/getUserWebViewColumnSize",
				{
					email: localStorage.getItem("email"),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then((res) => {
				if (res.data.success && res.data.data.length > 0) {
					this.setState({ columnsSize: res.data.data });
					localStorage.setItem(
						"columnsSize",
						JSON.stringify(res.data.data)
					);
				}
			})
			.catch((error) => {});

		// this.props.onFetchLeads();
		this.setState({ fetchingLeads: true });
		axios
			.get(
				baseURL() +
					"/api/v1/leads?limit=" +
					this.leadsToFetchOneTime +
					"&selection_string=" +
					this.selectionStrings,
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then(async (res) => {
				console.log("fetchAPI res");
				//end

				var filteredLeads = secretUserLeadFilter(res.data.data);

				if (filteredLeads.length > 0) {
					var starLeads = await filteredLeads?.filter(
						(lead) => lead.starred_by_user_ids
					);
					var reminderLead = await filteredLeads?.filter(
						(lead) => lead.reminder.length > 0
					);
					const filtData = await updateReminderLead(
						filteredLeads,
						this.state.allSystemUsers
					);
					const filtDataStar = await updateReminderLead(
						starLeads,
						this.state.allSystemUsers
					);
					const reminderFilterLeads = await updateReminderLeadData(
						reminderLead,
						this.state.allSystemUsers
					);
					this.setState(
						{
							starLeadsData: filtDataStar, //starLeads,
							filteredData: filtData, //res.data.data,
							allLeads: filteredLeads,
							tempData: filteredLeads,
							reminderLeadsData: reminderFilterLeads,
							fetchingLeads: false,
							autoFetchLead: true,
						},
						() => {
							if (this.props.location.search) {
								this.reminderTabClick();
								console.log(
									"params",
									this.props.location.search
								);
							} else {
								console.log(" no params");
							}
							console.log(
								"fetchMore",
								res.data.data.length,
								"--",
								this.state.allLeads.length,
								this.state.fetchingLeads ? "true" : "false"
							);
							if (
								res.data.data.length > 0 &&
								this.state.allLeads.length < 450
							) {
								this.autoFetchMoreData(res.data.data.length);
							}
						}
					);
					console.log("----------starleads data-----");
					this.LeadsScreenRef.setState({
						tempLeadsData: filteredLeads,
					});
				} else {
					console.log("no lead found");
					this.setState({ filteredData: [], fetchingLeads: false });
					// this.LeadsScreenRef.setState({
					// 	tempLeadsData: [],
					// });
					// this.refetchLeads();
				}
			})
			.catch((error) => {
				// this.setState({
				// 	fetchingLeads: false,
				// });
				// this.refetchLeads();
				console.log("error in fetch leads");
				if (error.toString().includes("403")) {
					this.onLogoutClick();
				}
			});

		axios
			.post(
				baseURL() + "/api/v1/users/getUserWebViewColumnOrder",
				{
					email: localStorage.getItem("email"),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then((res) => {
				if (res.data.success && res.data.data.length > 0) {
					this.setState({ columnsOrder: res.data.data });
					localStorage.setItem(
						"columnsOrder",
						JSON.stringify(res.data.data)
					);
				}
			})
			.catch((error) => {});

		/** hitting the /stats API to get the analytics data */
		// axios
		// 	.get(baseURL() + "/api/v1/stats", {
		// 		headers: {
		// 			"x-access-token": localStorage.getItem("jwtToken"),
		// 			"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
		// 		},
		// 	})
		// 	.then((res) => {
		// 		this.setState({ analyticsData: res.data.data });
		// 	})
		// 	.catch((error) => {
		// 		if (error.toString().includes("403")) {
		// 			this.onLogoutClick();
		// 		}
		// 	});

		let socket = io(baseURL(), { transports: ["websocket", "polling"] });
		console.log("socket---", socket);
		socket.on("connect", (data) => {
			localStorage.setItem("socket_id", socket.id);
		});
		socket.on("data_changed", (data) => {
			console.log("data_changed socket detected", data.value);
			this.allowToUpdate();
			this.refetchLeads(data.value);
			// this.refetchLeadsStats();
		});
		socket.on("lead_data_changed", (data) => {
			console.log("lead_data_changed socket detected");
			if (
				data &&
				data.leadId &&
				data.leadId !== "" &&
				data.type &&
				data.type !== ""
			) {
				this.updateLeadsData(
					data.leadId,
					data.type,
					data.newData ? data.newData : null
				);
			}
		});
	}
	componentDidUpdate(prevProps, prevState) {
		// console.log(
		// 	"prevState.allLeads.length",
		// 	prevState.allLeads.length,
		// 	"----",
		// 	this.state.allLeads.length,
		// 	this.state.columnsOrder.length
		// );
		// Check if the allLeads state has changed
		if (prevState.allLeads.length !== this.state.allLeads.length) {
			// console.log(
			// 	"componentDidUpdate-length",
			// 	this.state.allLeads.length,
			// 	this.state.autoFetchLead ? "true" : "false"
			// );
			if (this.state.allLeads.length < 1000 && this.state.autoFetchLead) {
				this.autoFetchMoreData(this.state.allLeads.length);
			}
		}
	}

	// componentDidUpdate() {
	// 	console.log("length", this.state.allLeads.length);
	// 	if (this.state.autofetch < 30 && this.state.autoFetchLead)
	// 		this.autoFetchMoreData(this.state.allLeads.length);
	// }
	handleSubCategoryChange = (catIndex, subIndex, value) => {
		console.log("handleSubCategoryChange");
		const newCategories = [...this.state.categories];
		if (value === "") {
			newCategories[catIndex].subCategories.splice(subIndex, 1);
		} else {
			newCategories[catIndex].subCategories[subIndex] = value;
		}
		this.setState({ categories: newCategories, isFilterAdded: true });
		// setCategories(newCategories);
		// setIsFilterAdded(true); // Mark that a filter has been added
	};
	handleResetFilters = () => {
		let init = [...this.initialCategories];
		this.setState({ categories: init, isFilterAdded: false });

		// setCategories([...initialCategories]);
		// setIsFilterAdded(false);
	};
	// handleApplyFilters = () => {
	// 	console.log("filter", this.state.categories);
	// 	onApplyFilters(this.state.categories);
	// };
	handleAddSavedFilter = (savedCategories, filterName) => {
		this.setState({
			filterName: filterName,
			categories: savedCategories,
		});
		// setFilterName(filterName);
		// console.log("savedCategories----", savedCategories);
		// setCategories(savedCategories);
	};
	//ma
	updateLeadsData = async (leadId, changeType, newChangedData) => {
		if (changeType === "delete-lead") {
			const newData = [...this.state.filteredData].filter(
				(lead) => lead._id !== leadId
			);
			const newData2 = newData.filter((lead) => lead.starred_by_user_ids);
			const reminderFilter = newData.filter(
				(lead) => lead.reminder.length > 0
			);

			// const reminderFilter = newData.filter(
			// 	(lead) => lead.reminder.length > 0
			// );
			this.setState({
				allLeads: newData,
				filteredData: newData,
				starLeadsData: newData2,
				reminderLeadsData: reminderFilter,
			});
			return;
		} else {
			console.log("leadschanged");
			const updatedData = await dataChangeForLead(
				this.state.renderFilter
					? this.state.filteredLeadsData
					:this.state.displaySearchBar?
					this.state.searchedLeads
					: this.state.filteredData,
				leadId,
				changeType,
				newChangedData
			);
			if (updatedData?.leads) {
				console.log("updateLeadsData else-4");

				// if (
				//   changeType === "deleteReminder" ||
				//   changeType === "reminder" ||
				//   changeType === "sold_status"
				// ) {

				const updates = await remindersUpdateLeads(
					updatedData.leads,
					this.state.allSystemUsers
				);
				console.log("search bar",updates.LeadScreen);

				// if (updates.LeadScreen.length > 0) {
				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: updates.LeadScreen,
					});
				}
				//ma
				else if(this.state.displaySearchBar){
					console.log("search bar",updates.LeadScreen);
					// const updatedLead=searchLeads2
					this.setState({searchedLeads:updates.LeadScreen})
				}
				else {
					this.setState({
						allLeads: updates.LeadScreen,
						filteredData: updates.LeadScreen,
						starLeadsData: updates.StarScreen,
						reminderLeadsData: updates.ReminderScreen,
					});
				}
				// } else {
				// 	this.refetchLeads();
				// }
			}
		}
	};
	autoFetchMoreData = (skip) => {
		console.log(
			"calling auto fetch ",
			this.state.autofetch + 1,
			" times",
			"skiped",
			skip
		);
		this.setState({ autofetch: this.state.autofetch + 1 });
		const pageSize = 30;
		// this.dataUpdated = true;
		axios
			.get(
				baseURL() +
					"/api/v1/leads?limit=" +
					pageSize * 1 +
					"&skip=" +
					skip +
					"&selection_string=" +
					this.selectionStrings,
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then(async (res) => {
				// console.log("ress", res.data.data);
				let temp = [...this.state.allLeads];
				temp = temp.concat(res.data.data);
				const newTemp = await updateReminderLead(
					temp,
					this.state.allSystemUsers
				);
				this.dataUpdated = true;
				// this.setState({ allLeads: temp });
				this.setState({ filteredData: newTemp, allLeads: temp });
				if (this.state.allLeads.length > 450) {
					this.setState({ autoFetchLead: false });
				}
				// this.fetchTimeout = setTimeout(() => {
				// 	if (
				// 		res.data.data.length > 0 &&
				// 		this.state.allLeads.length < 1400
				// 	)
				// 		this.autoFetchMoreData();
				// }, 200);
			})
			.catch((error) => {
				this.autoFetchMoreData(this.state.allLeads.length);
			});
	};

	emptyCache(current_version) {
		console.log("versionJson", current_version);
		this.setState({ version: current_version });
		if ("caches" in window) {
			caches.keys().then((names) => {
				// Delete all the cache files
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		}

		setTimeout(() => {
			localStorage.setItem("version", current_version);
		}, 200);
		// Makes sure the page reloads. Changes are only visible after you refresh.
		window.location.reload();
		// Makes sure the page reloads. Changes are only visible after you refresh.
	}

	clearCookies() {
		var cookies = document.cookie.split("; ");
		for (var c = 0; c < cookies.length; c++) {
			var d = window.location.hostname.split(".");
			while (d.length > 0) {
				var cookieBase =
					encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
					"=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
					d.join(".") +
					" ;path=";
				var p = window.location.pathname.split("/");
				document.cookie = cookieBase + "/";
				while (p.length > 0) {
					document.cookie = cookieBase + p.join("/");
					p.pop();
				}
				d.shift();
			}
		}
		console.log("cookies cleared");
	}

	onLogoutClick = () => {
		localStorage.clear();
		window.location.reload();
	};

	handleOpenLeadExportModal = () => {
		this.setState({ exportLeadModal: true });
	};

	handleCloseLeadExportModal = () => {
		this.setState({ exportLeadModal: false });
	};

	onExportCustomersClick = () => {
		this.dataUpdated = true;
		let temp = this.state.selectedTabIndex === 0 ? 95 : 170;
		if (this.state.showExportCustomersView) {
			this.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight + temp,
			});
		} else {
			this.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight - temp,
			});
		}
		this.setState({
			showExportCustomersView: !this.state.showExportCustomersView,
		});
	};
	onClickCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	onClickCloseProgressAlert = () => {
		this.setState({ showProgressAlert: false });
	};
	showAlert = (message, time = 2000) => {
		this.setState({ showAlert: true, alertMessage: message });
		// this.setState({ });
		setTimeout(() => {
			this.setState({ showAlert: false });
		}, time);
	};
	//this function will count total subfilters applied
	countSubCategories(newFilteredLeads) {
		return newFilteredLeads.reduce((total, item) => {
			if (item.subCategories && Array.isArray(item.subCategories)) {
				return total + item.subCategories.length;
			}
			return total;
		}, 0);
	}
	showProgressAlert = (message) => {
		this.setState({ showProgressAlert: true, alertMessage: message });
	};
	leadTabBtnClick = (tabIndex) => {
		console.log("lead");
		if (this.state.selectedTabIndex == 6) {
			localStorage.setItem(
				"displayableColumns",
				JSON.stringify(this.state.displayableColumns)
			);
		}

		var allUsersData = [...this.state.allUsersData];
		if (allUsersData[allUsersData.length - 1]._id == null) {
			allUsersData.splice(allUsersData.length - 1, 1);
			this.addingNewUser = false;
		}
		this.setState({ allUsersData, selectedTabIndex: tabIndex }, () => {
			this.filterData();
		});
		this.onTabChanged();
	};
	filterData = async (filteredUpdatedLeads = null) => {
		console.log("filterData---", filteredUpdatedLeads);
		this.dataUpdated = true;
		switch (this.state.selectedTabIndex) {
			case 0:
				// console.log("case0", this.state.allLeads?.length);
				// if (this.state.allLeads && this.state.allLeads?.length > 0) {

				const leaD = this.state.allLeads?.filter(
					(item) => typeof item === "object"
				);
				if (leaD && leaD.length !== 0) {
					const fData = await updateReminderLead(
						this.state.allLeads,
						this.state.allSystemUsers
					);
					if (this.state.renderFilter) {
						this.setState({
							filteredLeadsData: filteredUpdatedLeads
								? filteredUpdatedLeads
								: fData,
						});
					}
					 else {
						
						this.setState({
							filteredData: fData, //this.state.allLeads
						});
					}
				} else {
					if (this.state.renderFilter) {
						this.setState({ filteredLeadsData: [] });
					} else {
						this.setState({
							filteredData: [], //this.state.allLeads
						});
					}
				}
				// } else {
				// 	console.log("case no lead");
				// 	this.setState({
				// 		filteredData: [], //this.state.allLeads
				// 	});
				// }
				// this.allowToUpdate();

				// setTimeout(() => {
				//   this.setState({ itemskey: Math.random() });
				// }, 0);

				break;
			case 2:
				console.log("case2");
				let temp = this.state.allLeads;
				temp = temp.filter((lead) =>
					moment(lead.utc_time_stamp).isSame(new Date(), "day")
				);
				const filtData1 = await updateReminderLead(
					temp,
					this.state.allSystemUsers
				);
				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: filteredUpdatedLeads
							? filteredUpdatedLeads
							: filtData1,
					}); //set filtered leads if filtered applied
				} else {
					this.setState({
						filteredData: filtData1,
						// temp
					});
				}
				break;
			case 3:
				console.log("case3");
				// let temp1 = this.state.allLeads;
				// temp1 = temp1.filter(
				//   (lead) =>
				//     moment(lead.utc_time_stamp).isBefore(moment().subtract(14, "d")) &&
				//     lead.lead_status.value != "Completed"
				// );
				// this.setState({ filteredData: temp1 });
				let str = this.state.allLeads;
				str = str.filter((lead) => lead.starred_by_user_ids);

				let fData1 = await updateReminderLeadHotlist(
					str,
					this.state.allSystemUsers
				);
				// var array = str.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1));

				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: filteredUpdatedLeads
							? filteredUpdatedLeads
							: fData1, //set filtered leads if filtered applied
					});
				} else {
					this.setState({ starLeadsData: fData1 });
				}
				break;
			case 4:
				console.log("case4");
				let temp2 = this.state.allLeads;
				temp2 = temp2.filter(
					(lead) => lead.lead_status.value == "In Progress"
				);
				let fData3 = await updateReminderLead(
					temp2,
					this.state.allSystemUsers
				);
				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: filteredUpdatedLeads
							? filteredUpdatedLeads
							: fData3, //set filtered leads if filtered applied
					});
				} else {
					this.setState({ filteredData: fData3 });
				}
				break;
			case 5:
				console.log("case5");
				let temp3 = this.state.allLeads;
				temp3 = temp3.filter(
					(lead) => lead.lead_status.value == "Completed"
				);
				let fData2 = await updateReminderLead(
					temp3,
					this.state.allSystemUsers
				);
				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: filteredUpdatedLeads
							? filteredUpdatedLeads
							: fData2, //set filtered leads if filtered applied
					});
				} else {
					this.setState({ filteredData: fData2 });
				}
				break;
			case 8:
				console.log("case8", this.state.allLeads);

				let remind = this.state.allLeads;
				remind = remind.filter((lead) => lead?.reminder?.length > 0);
				let fData8 = await updateReminderLeadData(
					remind,
					this.state.allSystemUsers
				);
				if (this.state.renderFilter) {
					this.setState({
						filteredLeadsData: filteredUpdatedLeads
							? filteredUpdatedLeads
							: fData8, //set filtered leads if filtered applied
					});
				} else {
					this.setState({ filteredData: fData8 });
				}
				break;
			default:
				let fData4 = await updateReminderLead(
					this.state.allLeads,
					this.state.allSystemUsers
				);
				this.setState({
					filteredData: fData4,
				});
				break;
		}
	};
	userTabBtnClick = () => {
		this.props.addSelectedSmsFilter("");
		this.props.addSelectedFilter("");
		this.setState({ selectedTabIndex: 1 });
		this.onTabChanged();
	};
	analyticsTabClick = () => {
		this.props.addSelectedSmsFilter("");
		this.props.addSelectedFilter("");
		this.setState({ selectedTabIndex: 2 });
		this.onTabChanged();
	};

	starTabClick = async () => {
		this.props.addSelectedSmsFilter("");
		this.props.addSelectedFilter("");
		this.setState({ selectedTabIndex: 3 });

		let str = this.state.allLeads;
		console.log("SelectedColumns", this.state.displayableColumns);
		str = str.filter((lead) => lead.starred_by_user_ids);
		// var array = str.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1));
		const upDAta = await updateReminderLeadHotlist(
			str,
			this.state.allSystemUsers
		);
		this.setState({
			starLeadsData: upDAta, //array
		});
		this.onTabChanged();
	};
	reminderTabClick = async () => {
		this.props.addSelectedSmsFilter("");
		this.props.addSelectedFilter("");
		this.setState({ selectedTabIndex: 8 });

		let remind = this.state.allLeads;
		remind = remind.filter((lead) => lead.reminder.length > 0);
		// var array = str.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1));
		const upDAta = await updateReminderLeadData(
			remind,
			this.state.allSystemUsers
		);
		// const ffff = await FilterRemindersForUser(upDAta);
		console.log("remindLeadssss", upDAta);
		this.setState({
			reminderLeadsData: upDAta, //array
		});
		this.onTabChanged();
	};

	templateTabClick = () => {
		if (this.state.selectedTabIndex !== 7) {
			this.props.addSelectedSmsFilter("");
			this.props.addSelectedFilter("");
		}
		this.setState({ selectedTabIndex: 7 });
		// let str = this.state.allLeads;
		// str = str.filter((lead) => lead.starred_by_user_ids);
		// var array = str.sort((a, b) => a.updated_at > b.updated_at ? 1 : -1);
		// this.setState({ starLeadsData: array });
		// this.onTabChanged();
	};
	openOmegaForm = () => {
		window.open(
			"https://assistant.omegaclients.com/",
			"_blank",
			"noopener,noreferrer"
		);
	};

	onTabChanged = () => {
		console.log("tab change");
		this.setState({
			showExportCustomersView: false,
		});
		this.setState({ innerAvailableHeight: window.innerHeight - 60 });
	};
	// onTabClick = (index) => {
	//   if (this.state.selectedTabIndex == 6) {
	//     localStorage.setItem(
	//       "displayableColumns",
	//       JSON.stringify(this.state.displayableColumns)
	//     );
	//   }
	//   if (index !== this.state.selectedTabIndex)
	//     this.setState({ selectedTabIndex: index });
	// };

	updateAutoFollowUpStatus = (flag) => {
		this.confirmAction(
			"Are you sure?",
			"This will change auto follow-up functionality.",
			() => {
				axios
					.put(
						baseURL() + "/api/v1/app-data",
						{
							auto_followup_enabled: flag,
						},
						{
							headers: {
								"x-access-token":
									localStorage.getItem("jwtToken"),
								"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
							},
						}
					)
					.then((res) => {
						this.setState({
							isFollowUpEnabled:
								res.data.data.auto_followup_enabled,
						});
					})
					.catch((error) => {});
			},
			() => {}
		);
	};
	allowToUpdate = () => {
		this.dataUpdated = true;
	};
	fetchMoreData = (pageSize, pageIndex) => {
		console.log("fetch more");
		this.dataUpdated = true;
		if (this.state.selectedTabIndex === 0) {
			axios
				.get(
					baseURL() +
						"/api/v1/leads?limit=" +
						pageSize * 6 +
						"&skip=" +
						this.state.allLeads.length +
						"&selection_string=" +
						this.selectionStrings,
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					}
				)
				.then(async (res) => {
					let temp = [...this.state.allLeads];
					temp = temp.concat(res.data.data);
					const secretFilteredLeads = secretUserLeadFilter(temp);
					const newTemp = await updateReminderLead(
						secretFilteredLeads,
						this.state.allSystemUsers
					);
					this.dataUpdated = true;
					// this.setState({ allLeads: temp });
					this.setState({
						filteredData: newTemp,
						allLeads: secretFilteredLeads,
					});
					// setTimeout(() => {
					// 	if (this.state.allLeads.length < 1400)
					// 		this.autoFetchMoreData();
					// }, 200);
				})
				.catch((error) => {});
		} else {
			axios
				.get(
					baseURL() +
						"/api/v1/users?limit=" +
						pageSize * 3 +
						"&skip=" +
						(pageIndex + 1),
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					}
				)
				.then((res) => {
					var users = res.data.data;
					this.setState({ allUsersData: users });
				})
				.catch((error) => {});
		}
	};
	refetchLeads = (alertType = null) => {
		console.log("refetchLeads", alertType);
		if (alertType) {
			setTimeout(() => {
				this.showProgressAlert(`Fetching Updated Leads...`);
			}, 600);
		}
		this.dataUpdated = true;
		axios
			.get(
				baseURL() +
					"/api/v1/leads?limit=" +
					450 + //this.leadsToFetchOneTime
					"&selection_string=" +
					this.selectionStrings,
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then(async (res) => {
				console.log("refetchLeads");
				const filteredLeads = secretUserLeadFilter(res.data.data);
				this.dataUpdated = true;
				const UpdatesLeads = await updateReminderLead(
					filteredLeads,
					this.state.allSystemUsers
				);
				console.log(
					"refetchLeads filter",
					UpdatesLeads.length === 0,
					this.state.showProgressIndicator
				);

				// if (UpdatesLeads.length === 0) {
				// 	this.setState({
				// 		filteredLeads: UpdatesLeads,
				// 		allLeads: UpdatesLeads,
				// 	});
				// } else {
				if (this.state.renderFilter) {
					console.log(
						"filteredLeadsData",
						this.state.filteredLeadsData?.length
					);
					const filteredUpdatedLeads = UpdatesLeads.filter((lead) =>
						this.state.filteredLeadsData.some(
							(filteredLead) => filteredLead._id === lead._id
						)
					);
					console.log("matchingLeads", filteredUpdatedLeads);
					this.setState(
						{
							filteredLeadsData: filteredUpdatedLeads,
							showProgressIndicator: false,
							fetchingLeads: false,
							showProgressAlert: false,
						},
						() => {
							this.filterData(filteredUpdatedLeads);
						}
					);
				} else {
					this.setState(
						{
							allLeads: UpdatesLeads,
							showProgressIndicator: false,
							fetchingLeads: false,
							showProgressAlert: false,
						},
						() => {
							console.log(
								"showProgressIndicator",
								this.state.showProgressIndicator
							);
							this.filterData();
						}
					);
				}

				this.LeadsScreenRef.setState({ searchInProgress: false });

				// }
			})
			.catch((error) => {
				this.showAlert("Error in pulling leads");
				this.setState({
					showProgressIndicator: false,
					fetchingLeads: false,
					showProgressAlert: false,
				});
			});
	};
	refetchLeadsStats = () => {
		this.dataUpdated = true;
		axios
			.get(baseURL() + "/api/v1/stats", {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				},
			})
			.then((res) => {
				this.dataUpdated = true;
				this.setState({ analyticsData: res.data.data });
			})
			.catch((error) => {
				if (error.toString().includes("403")) {
					this.onLogoutClick();
				}
			});
	};
	updateData = (id, data, shouldUpdate = false, callback = null) => {
		console.log("updateData-7");
		let allLeads = [...this.state.allLeads];

		let index = allLeads.findIndex((item) => item._id === id);
		allLeads.splice(index, 1, data);

		// var starLeads = res.data.data.filter(
		// (lead) => lead.starred_by_user_ids.length > 0 );

		this.setState({ allLeads: allLeads }, () => {
			this.filterData();
			// console.log("-----update data---");
		});
		if (callback) callback();
	};
	updateSearchData = (
		data,
		valueToSearch,
		shouldUpdate = false,
		callback = null
	) => {
		console.log("updateSearchData", data);
		let allLeads = [...this.state.allLeads];
		allLeads = data;
		// case: data length = 1 and value to search mai kuch ho

		if (allLeads.length == 0) {
			console.log("case:1");
			this.LeadsScreenRef.setState({ searchInProgress: true });
			this.refetchLeads();
		} else if (valueToSearch == "") {
			console.log("empty case",this.state.filteredData.length);
			// this.setState({ allLeads:allLeads },
				//  () => {
				this.filterData();
			// };
		} else if (valueToSearch != "") {
			console.log("case:2");
			if (this.state.searchInputVal.trim() == "") {
				this.LeadsScreenRef.setState({ searchInProgress: true });
				this.refetchLeads();
			} else {
				this.setState({ allLeads: allLeads }, () => {
					this.filterData();
				});
			}
		} else if (data.length > 150) {
			console.log("case:2");
			this.setState({ allLeads: allLeads }, () => {
				this.filterData();
			});
		} else {
			this.LeadsScreenRef.setState({ searchInProgress: true });
			this.refetchLeads();
		}
		if (callback) callback();
	};

	// updateSearchData = (
	// 	data,
	// 	valueToSearch,
	// 	shouldUpdate = false,
	// 	callback = null
	// ) => {
	// 	console.log(
	// 		"updateSearchData",
	// 		this.state.allLeads.length,
	// 		"--",
	// 		data.length
	// 	);
	// 	let allLeads = [...this.state.allLeads];
	// 	allLeads = data;
	// 	// case: data length = 1 and value to search mai kuch ho

	// 	if (allLeads.length == 0 && valueToSearch == "") {
	// 		this.LeadsScreenRef.setState({ searchInProgress: true });
	// 		this.refetchLeads();
	// 	} else if (valueToSearch != "") {
	// 		if (this.state.searchInputVal.trim() == "") {
	// 			this.LeadsScreenRef.setState({ searchInProgress: true });
	// 			this.refetchLeads();
	// 		} else {
	// 			this.setState({ allLeads: allLeads }, () => {
	// 				this.filterData();
	// 			});
	// 		}
	// 	} else if (data.length > 150) {
	// 		this.setState({ allLeads: this.state.allLeads }, () => {
	// 			this.filterData();
	// 		});
	// 		// setTimeout(() => {
	// 		// 	if (this.state.allLeads.length < 1400) this.autoFetchMoreData();
	// 		// }, 200);
	// 	} else {
	// 		this.LeadsScreenRef.setState({ searchInProgress: true });
	// 		this.refetchLeads2();
	// 	}

	// 	if (callback) callback();
	// };
//////
//ma
clearSearchHeader=()=>{
	this.setState({showSearchHeader:false,displaySearchBar:false})

}
openSearchHeader=()=>{
	if(this.state.searchInputVal!="")
	this.setState({showSearchHeader:true})
else
this.setState({displaySearchBar:false})

}
searchLead = debounce((val) => {
	// Search locally for leads
	const localLeads = this.searchLeads(val);
	console.log("Local search result", localLeads);
  
	// Function to process leads (local or from API)
	const processLeads = (leads) => {
	  const filteredLeads = secretUserLeadFilter(leads);
	  if (filteredLeads.length === 0 && val.trim() !== "") {
		filteredLeads.length = 1; // Ensure at least one result
	  }
	  return filteredLeads;
	};
  
	// Update UI with local leads immediately
	const filteredLocalLeads = processLeads(localLeads);
	this.setState({ searchedLeads: filteredLocalLeads });
	console.log("filteredLeads count",filteredLocalLeads?.length);
  
	// If no local leads found, show loader and make API call
	if (localLeads.length === 0) {
	  if (val && val.trim().length > 0) {
		console.log("No local results found. Performing API search...");
  
		// Show loader
		this.LeadsScreenRef.setState({ searchInProgress: true });
  
		// Cancel any ongoing requests
		if (this.source) {
		  this.source.cancel("Operation canceled due to new request.");
		}
  
		// Create a new CancelToken source
		this.source = axios.CancelToken.source();
  
		axios
		  .post(
			baseURL() + "/api/v1/leads/searchLead",
			{ search_text: val },
			{
			  headers: {
				"x-access-token": localStorage.getItem("jwtToken"),
				"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				socket_id: localStorage.getItem("socket_id"),
			  },
			  cancelToken: this.source.token,
			}
		  )
		  .then((response) => {
			const apiLeads = response.data.message;
			console.log("API search result", apiLeads);
  
			// Process and append the API leads to the state
			const filteredApiLeads = processLeads(apiLeads);
			this.setState((prevState) => ({
			  searchedLeads: [...prevState.searchedLeads, ...filteredApiLeads]
			}));
  
			// Hide loader
			this.LeadsScreenRef.setState({ searchInProgress: false });
		  })
		  .catch((error) => {
			if (axios.isCancel(error)) {
			  console.log("Request canceled", error.message);
			} else {
			  console.log("Search failed", error);
			}
			this.LeadsScreenRef.setState({ searchInProgress: false });
		  });
	  }
	} else {
	  // Local results found, still make API call but without showing the loader
	  console.log("Local results found, fetching more from API...");
  
	  // Cancel any ongoing requests
	  if (this.source) {
		this.source.cancel("Operation canceled due to new request.");
	  }
  
	  // Create a new CancelToken source
	  this.source = axios.CancelToken.source();
  
	  axios
		.post(
		  baseURL() + "/api/v1/leads/searchLead",
		  { search_text: val },
		  {
			headers: {
			  "x-access-token": localStorage.getItem("jwtToken"),
			  "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
			  socket_id: localStorage.getItem("socket_id"),
			},
			cancelToken: this.source.token,
		  }
		)
		.then((response) => {
		  const apiLeads = response.data.message;
		  console.log("API search result", apiLeads);
  
		  // Process and append the API leads to the state
		  const filteredApiLeads = processLeads(apiLeads);
		  this.setState((prevState) => ({
			searchedLeads: [...prevState.searchedLeads, ...filteredApiLeads]
		  }), () => {
			console.log("filteredLeads count", this.state.searchedLeads?.length);
		  });
		  

		})
		.catch((error) => {
		  if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		  } else {
			console.log("Search failed", error);
		  }
		});
	}
  }, 400);
  
  // Local search function
  searchLeads = (searchText) => {
	console.log("searchText", searchText);
	const currentLeads = secretUserLeadFilter(this.state.filteredData);
	const regex = new RegExp(searchText, "i"); // Case-insensitive regex
  
	return currentLeads
	  .filter((lead) => {
		// Match search text with lead properties
		return (
		  (lead.name && regex.test(lead.name)) ||
		  (lead.source && regex.test(lead.source)) ||
		  (lead.channel && regex.test(lead.channel)) ||
		  (lead.email && regex.test(lead.email)) ||
		  (lead.address && regex.test(lead.address)) ||
		  (lead.category && lead.category.name && regex.test(lead.category.name)) ||
		  (lead.phone_number && regex.test(lead.phone_number)) ||
		  (lead.stripped_phone_number && regex.test(lead.stripped_phone_number)) ||
		  (lead.svc_requested && regex.test(lead.svc_requested)) ||
		  (lead.notes && lead.notes.some(noteObj => noteObj.note && regex.test(noteObj.note))) ||
		  (lead.extra_links && regex.test(lead.extra_links))
		);
	  })
	  .sort((a, b) => b._id - a._id) // Sort by _id in descending order
	  .slice(0, 100); // Limit to 100 results
  };
  
// searchLead = debounce((val) => {
// 	// Search locally for leads
// 	const localLeads = this.searchLeads(val);
// 	console.log("Local search result", localLeads);

// 	// Process local leads similarly to API response
// 	const processLeads = (leads) => {
// 		// Apply the same filtering or processing to the local leads as you would to API results
// 		const filteredLeads = secretUserLeadFilter(leads);
// 		if (filteredLeads.length === 0 && val.trim() !== "") {
// 			filteredLeads.length = 1; // Ensure at least one result
// 		}
// 		return filteredLeads;
// 	};

// 	// If no leads are found locally, proceed with API call
// 	if (localLeads.length === 0) {
// 		if (val && val.toString().trim().length > 0) {
// 			console.log("search api call");
// 			// Cancel any ongoing requests
// 			if (this.source) {
// 				this.source.cancel(
// 					"Operation canceled due to new request."
// 				);
// 			}

// 			// Create a new CancelToken source
// 			this.source = axios.CancelToken.source();
// 			this.LeadsScreenRef.setState({ searchInProgress: true });
// 			// this.setState({ searchInProgress: true });
// 			axios
// 				.post(
// 					baseURL() + "/api/v1/leads/searchLead",
// 					{
// 						search_text: val,
// 					},
// 					{
// 						headers: {
// 							"x-access-token":
// 								localStorage.getItem("jwtToken"),
// 							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
// 							socket_id: localStorage.getItem("socket_id"),
// 						},
// 						cancelToken: this.source.token,
// 					}
// 				)
// 				.then((response) => {
// 					const apiLeads = response.data.message;
// 					this.LeadsScreenRef.setState({ searchInProgress: false });
//                     console.log("apiLeads",apiLeads);
// 					const filteredApiLeads = processLeads(apiLeads);
// 					this.setState({
// 						searchedLeads:filteredApiLeads
// 					})

// 					// this.setState({ searchInProgress: false });
// 					this.allowToUpdate();
// 					this.updateSearchData(
// 						filteredApiLeads,
// 						val,
// 						true,
// 						() => {}
// 					);
// 				})
// 				.catch((error) => {
// 					if (axios.isCancel(error)) {
// 						console.log("Request canceled", error.message);
// 					} else {
// 						console.log("Search failed", error);
// 					}
// 					this.LeadsScreenRef.setState({ searchInProgress: false });
// 				});
// 		} else {
// 			console.log("Not Searchable", this.state.tempLeadsData.length);
// 			this.allowToUpdate();
// 			this.updateSearchData(
// 				this.state.tempLeadsData,
// 				val,
// 				true,
// 				() => {}
// 			);
// 		}
// 	} else {
// 		// Process local leads similarly to API results
// 		const filteredLocalLeads = processLeads(localLeads);
// 		this.setState({
// 			searchedLeads:filteredLocalLeads
// 		})
// 		// this.props.updateSearchData(
// 		// 	filteredLocalLeads,
// 		// 	val,
// 		// 	true,
// 		// 	() => {}
// 		// );
// 	}
// }, 400);

// searchLeads = (searchText) => {
// 	console.log("searchText",searchText);
// 	const currentLeads=secretUserLeadFilter(this.state.filteredData)
// 	// let matchedLeads = [];
// 	const regex = new RegExp(searchText, "i"); // Create a case-insensitive regular expression

// 	return currentLeads
// 		.filter((lead) => {
// 			// Check all conditions
// 			return (
// 				(lead.name && regex.test(lead.name)) ||
// 				(lead.source && regex.test(lead.source)) ||
// 				(lead.channel && regex.test(lead.channel)) ||
// 				(lead.email && regex.test(lead.email)) ||
// 				(lead.address && regex.test(lead.address)) ||
// 				(lead.category &&
// 					lead.category.name &&
// 					regex.test(lead.category.name)) ||
// 				(lead.phone_number && regex.test(lead.phone_number)) ||
// 				(lead.stripped_phone_number &&
// 					regex.test(lead.stripped_phone_number)) ||
// 				(lead.svc_requested && regex.test(lead.svc_requested)) ||
// 				(lead.notes &&
// 					lead.notes.some(
// 						(noteObj) =>
// 							noteObj.note && regex.test(noteObj.note)
// 					)) ||
// 				(lead.extra_links && regex.test(lead.extra_links))
// 			);
// 		})
// 		.sort((a, b) => b._id - a._id) // Sort by _id in descending order
// 		.slice(0, 100); // Limit to 100 results
// };


	//////
	updateDataByID = async (
		id,
		data,
		shouldUpdate = false,
		callback = null
	) => {
		let allLeads = [...this.state.allLeads];
		let index = allLeads.findIndex((item) => item._id === id);
		let message = "Changes Saved";
		if (!allLeads[index].is_complete && data.is_complete) {
			message =
				"Changes Saved, Lead has been moved to completed lead section, change filter to see lead.";
		} else if (allLeads[index].is_complete && !data.is_complete) {
			message =
				"Changes Saved, Lead has been moved to un-completed lead section, change filter to see lead.";
		}
		allLeads.splice(index, 1, data);
		this.setState({ allLeads: allLeads }, () => {
			this.filterData();
			this.showAlert(message, 5000);
		});
		if (callback) callback();
	};
	UpdateLeadAfterAutoFollowUp = (id, data, type) => {
		if (data != null) {
			let allLeads = [...this.state.allLeads];
			let index = allLeads.findIndex((item) => item._id === id);
			let message = "Canned message sent";
			if (type === "email") {
				message = "Canned Email Sent";
			} else if (type === "phone") {
				message = "Canned SMS Sent";
			} else if (type === "review") {
				message = "Review Sent";
			} else if (type === "referral") {
				message = "Referral Sent";
			}

			allLeads.splice(index, 1, data);
			this.setState({ allLeads: allLeads }, () => {
				this.filterData();
				this.showAlert(message, 5000);
			});
		}
		this.setState({ showProgressIndicator: false });
	};
	UpdateLeadAfterAssigningSequence = async (id, data) => {
		if (data != null) {
			let allLeads = [...this.state.allLeads];
			let index = await allLeads.findIndex((item) => item._id === id);

			await allLeads.splice(index, 1, data);
			// console.log("AllLeads are", index, allLeads);
			this.setState({ allLeads: allLeads }, () => {
				this.filterData();
			});
		}
		this.setState({ showProgressIndicator: false });
	};
	setLoadingStatus = (status) => {
		this.setLoadingStatus = status;
	};
	isDataUpdated = () => {
		let temp = this.dataUpdated;
		this.dataUpdated = true;
		return temp;
	};
	onAddNewUserButtonClick = (onEmptyUserAdded) => {
		if (this.addingNewUser) {
			return;
		}
		this.addingNewUser = true;
		var obj = {
			name: "",
			user: "",
			phone_number: "",
			color: "#000000",
			nick_name: "",
		};
		var allUsersData = [...this.state.allUsersData];
		allUsersData.splice(allUsersData.length, 1, obj);
		this.setState({ allUsersData });
		onEmptyUserAdded(obj);
		// this.userScreenRef.onEmptyUserAdded(obj);
		this.allowToUpdate();
	};

	getAllUserArrayofKey = (users) => {
		var usersname = [];
		for (var i = 0; i < users.length; i++) {
			let nickName = users[i].name;
			// if (isDeveloper.toString() == "true") {
			let obj = {
				label: users[i]?.nick_name
					? users[i].nick_name
					: capitalizeName(nickName),
				name: users[i].name,
				value: users[i].user,
				color: users[i].color,
				id: users[i]._id,
			};
			usersname.push(obj);
			// } else if (
			//   !users[i].isDeveloper ||
			//   users[i].isDeveloper.toString() == "false"
			// ) {
			//   var obj = {
			//     label: users[i].name,
			//     value: users[i].user,
			//     color: users[i].color,
			//     id: users[i]._id,
			//   };
			//   usersname.push(obj);
			// }
		}
		usersname.push({
			value: "unassign",
			label: "Un-Assign",
			color: "#fff",
			name: "unassign",
			id: "unassign",
		});

		return usersname;
	};
	getArrayofKey = (users) => {
		//ISDEVELOPER CHECK CONTROL
		let isDeveloper = localStorage.getItem("isDeveloper");
		if (isDeveloper == null) {
			this.onLogoutClick();
		}
		var usersname = [];
		for (var i = 0; i < users.length; i++) {
			if (isDeveloper.toString() == "true") {
				let obj = {
					label: users[i].name,
					value: users[i].user,
					color: users[i].color,
					id: users[i]._id,
				};
				usersname.push(obj);
			} else if (
				!users[i].isDeveloper ||
				users[i].isDeveloper.toString() == "false"
			) {
				var obj = {
					label: users[i].name,
					value: users[i].user,
					color: users[i].color,
					id: users[i]._id,
				};
				usersname.push(obj);
			}
		}
		usersname.push({
			value: "unassign",
			label: "Un-Assign",
			color: "#fff",
			id: "unassign",
		});

		return usersname;
	};
	OnUserUpdated = (user, newUser, rowToUpdate) => {
		var users = [...this.state.allUsersData];
		if (newUser) {
			users.pop();
			users.push(user);
		} else {
			users[rowToUpdate] = user;
		}
		var userList = this.getAllUserArrayofKey(users);
		var filterUser = secretUserCheck(userList);
		this.setState({
			showExportCustomersView: false,
			allUsersData: users,
			allSystemUsers: filterUser,
			showProgressIndicator: false,
			users: this.getArrayofKey(users),
		});

		// this.props.addUserData(this.getAllUserArrayofKey(users));
		this.props.addUserData(filterUser);
	};
	OnUpdateUserColor = (email, color) => {
		let users = [...this.state.allUsersData];
		users.forEach((item, index) => {
			if (item.user === email) {
				item.color = color;
			}
		});

		this.setState({
			allUsersData: users,
			users: this.getArrayofKey(users),
		});
	};
	OnUserDeleted = (index) => {
		var users = [...this.state.allUsersData];
		users.splice(index, 1);
		this.setState({
			allUsersData: users,
			showProgressIndicator: false,
			users: this.getArrayofKey(users),
		});
	};
	exportLeadCSV = () => {
		this.setState({
			CSVFileName:
				"LMS-Filtered-Customers-" + new Date().toISOString() + ".csv",
		});

		let data = this.props.filterData;
		let uniqueData = new Map(data.map((item) => [item._id, item]));
		uniqueData = Array.from(uniqueData.values());
		const temp = uniqueData.map(
			({ name, phone_number, email, address }) => ({
				name,
				phone_number,
				email,
				address,
			})
		);

		console.log("tempdata", temp);
		this.setState(
			{
				exportableData: temp,
				isCSVAvailable: true,
			},
			() => {
				// Programmatically click the CSVLink to trigger the download
				this.csvLinkEl.current.link.click();
			}
		);
	};

	OnSaveNewUser = (user, newUser = true, rowIndex = 0) => {
		this.setState({ showProgressIndicator: true });
		user.email = user.user;
		axios
			.post(baseURL() + "/api/v1/users/", user, {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
				},
			})
			.then((res) => {
				this.addingNewUser = false;
				this.OnUserUpdated(res.data.data, newUser, rowIndex);
			})
			.catch((error) => {
				this.addingNewUser = false;
				this.setState({
					isInProgress: false,
				});
			});
	};
	onDeleteLead = (leadID) => {
		let allLeads = [...this.state.allLeads];
		let index = allLeads.findIndex((item) => item._id === leadID);
		allLeads.splice(index, 1);
		this.setState({ allLeads: allLeads }, () => {
			this.filterData();
		});
	};
	OnDeleteUser = (index, callback) => {
		this.addingNewUser = false;
		if (this.state.allUsersData[index] != null) {
			if (this.state.allUsersData[index]._id == null) {
				var allUsersData = [...this.state.allUsersData];
				allUsersData.splice(-1, 1);
				this.setState({ allUsersData });
			} else {
				this.setState({ showProgressIndicator: true });
				var usersToRemove = [];
				usersToRemove.push(this.state.allUsersData[index].user);
				var data = {
					emails: usersToRemove,
				};
				axios
					.post(baseURL() + "/api/v1/users/delete-users", data, {
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					})
					.then((res) => {
						this.OnUserDeleted(index);
						callback();
					})
					.catch((error) => {
						this.setState({
							isInProgress: false,
						});
					});
			}
		} else {
			let allUsersData = [...this.state.allUsersData];
			allUsersData.splice(-1, 1);
			this.setState({ allUsersData });
		}
	};
	OnEditClicked = (index) => {
		console.log("OnEditClicked");
		var lastUser =
			this.state.allUsersData[this.state.allUsersData.length - 1];
		if (
			lastUser.name == "" &&
			lastUser.phone_number == "" &&
			lastUser.user == ""
		) {
			this.OnDeleteUser(this.state.allUsersData.length - 1);
		}
	};
	//warda
	confirmReviewAction = (lead, title, message, noCallback, callback) => {
		confirmAlert({
			customUI: ({ onClose }) => (
				<RefReview
					lead={lead}
					title={title}
					message={message}
					noCallback={onClose}
					callback={callback}
				/>
			),
		});
	};
	confirmAction = (title, message, yesCallback, noCallback) => {
		confirmAlert({
			title: title,
			message: message,
			buttons: [
				{
					label: "Yes",
					onClick: () => yesCallback(),
				},
				{
					label: "No",
					onClick: () => noCallback(),
				},
			],
		});
	};
	headerCollapseButtonClick = () => {
		this.setState({ headerCollapse: !this.state.headerCollapse }, () => {
			let temp = 0;
			let _temp = this.state.headerCollapse ? 10 : 60;
			this.allowToUpdate();
			this.setState({
				innerAvailableHeight: window.innerHeight - _temp - temp,
			});
			localStorage.setItem(
				"headercollapsed",
				this.state.headerCollapse ? "1" : 0
			);
		});
	};
	onCSVGenerate = (data, fileName) => {
		this.setState({ exportableData: data, CSVFileName: fileName });
	};
	selectRowByAssignee = (value) => {
		this.setState({ SelectedUser: value });
		if (value != null) {
			if (
				value == "" ||
				this.state.users.find((item) => item.label === value) == null
			) {
				this.setState({ highlightColor: "#d8d8d8" });
			} else {
				this.setState({
					highlightColor:
						this.state.users.find((item) => item.label === value)
							.color + "22",
				});
			}
		}
	};
	getRowColorByAssignee = (value) => {
		if (value != null) {
			if (
				value == "" ||
				this.state.users?.find((item) => item.label === value) == null
			) {
				return "#ffff";
			} else {
				return (
					this.state.users?.find((item) => item.label === value)
						.color + "20"
				);
			}
		}
	};

	enableDevMode = () => {
		let devModeEnabled = localStorage.getItem("isDevModeEnabled");
		localStorage.setItem(
			"isDevModeEnabled",
			devModeEnabled == "0" ? "1" : "0"
		);
		window.location.reload();
	};
	shouldShowDevContril = () => {
		let allowedIDs = [];
		allowedIDs.push("umer@binexsolutions.com");
		allowedIDs.push("faiqa.shakeel@binexsolutions.com");
		allowedIDs.push("hira.tariq@binexsolutions.com");
		allowedIDs.push("asadullah.riaz@discretelogix.com");

		if (allowedIDs.indexOf(localStorage.getItem("email")) > -1)
			return "block";
		else return "none";
	};

	generateTabView = () => {
		return (
			<Col
				md={1}
				onClick={() => {
					this.allowToUpdate();
					this.selectRowByAssignee(null);
				}}
				style={{
					display: "flex",
					flexDirection: "row",
					color: "#8592AD",
					maxHeight: 70,
					padding: 0,
				}}
			>
				<div
					style={{}}
					className={
						this.state.selectedTabIndex === 0
							? "tabElement active-tab"
							: "tabElement"
					}
					onClick={() => {
						this.leadTabBtnClick(0);
					}}
				>
					<button
						style={{
							width: 30,
							height: 20,
							marginTop:
								this.state.selectedTabIndex === 0 ? 0 : 5,
							backgroundImage: `url(${
								this.state.selectedTabIndex === 0
									? leadActiveIcon
									: leadsIcon
								// leadsIcon
							})`,
						}}
					></button>
					<span
						style={{
							marginTop:
								this.state.selectedTabIndex === 0 ? 1 : 5,
							marginLeft: -12,
							fontFamily: "SF Pro Display Semibold",
							color:
								this.state.selectedTabIndex === 0
									? "#4256D0"
									: "#000000",
						}}
					>
						{this.state.firstTabText}
					</span>
				</div>
			</Col>
		);
	};
	onUpdateColumnSelection = (objectKey, columnKey) => {
		let tempList = this.state.displayableColumns;
		let tempListIndex = tempList.findIndex((e) => e.key == objectKey);
		let temp = tempList[tempListIndex];
		let tempIndex = temp.selectedColumns.findIndex((e) => e == columnKey);
		if (tempIndex >= 0) {
			temp.selectedColumns.splice(tempIndex, 1);
		} else {
			temp.selectedColumns.push(columnKey);
		}
		tempList.splice(tempListIndex, 1, temp);
		this.setState({ displayableColumns: tempList });
	};

	toggleSearchBar = () => {
		this.setState({searchedLeads:this.state.filteredData})
		if (this.state.displaySearchBar == true) {
			this.state.displaySearchBar = false;
			this.clearSearchBar();
		} else {
			if (this.state.searchInputVal && this.state.searchInputVal !== "")
				this.clearSearchBar();
			this.state.displaySearchBar = true;
		}
	};

	clearSearchBar = () => {
		console.log("clear search");
		this.setState({ searchInputVal: "" });
		this.LeadsScreenRef.searchLead("");
	};

	handleOpenFilterModal = () => {
		this.setState({
			filterModal: true,
		});
	};
	handleCloseFilterModal = () => {
		this.setState({
			filterModal: false,
		});
	};
	applyFilters = async (filters) => {
		this.showProgressAlert(
			"Loading... Your data will be available shortly. This may take few minutes.",
			filters
		);
		this.setState({ loader: true });
		const params = {
			filters: JSON.stringify(filters), // Convert filters to JSON string
		};

		try {
			const response = await fetchFilteredLeads(params);

			this.setState({ loader: false, showProgressAlert: false });

			if (response.data.success) {
				console.log("Filtered leads:", response.data.data);
				return response.data.data;
			} else {
				this.setState({ loader: false, showProgressAlert: false });
				console.error(
					"Error fetching filtered leads:",
					response.data.message
				);
				return [];
			}
		} catch (error) {
			this.setState({ loader: false, showProgressAlert: false });
			console.error("Error fetching filtered leads:", error);
			return [];
		}
	};

	handleClearFilters = () => {
		this.setState({
			renderFilter: false,
			filtersCount: 0,
		});
	};
	setFilterCount = (count) => {
		this.setState({ filtersCount: count });
	};
	// setFilterName = (name) => {
	// 	this.setState({ filterName: name });
	// };
	handleAddSubCategory = (index, newSubCategories) => {
		const newCategories = this.state.categories.map((cat, ind) =>
			ind === index
				? { ...cat, subCategories: [...newSubCategories] }
				: cat
		);
		this.setState({ categories: newCategories, isFilterAdded: true });

		// setCategories(newCategories);
		// setIsFilterAdded(true); // Mark that a filter has been added
	};
	handleApplyFilters = async (filters) => {
		const { categories } = this.state;

		const subCategoriesCount = this.countSubCategories(categories);

		console.log("filters count", subCategoriesCount);
		if (subCategoriesCount < 1) {
			this.showAlert("Please select any filter");
			return;
		} else {
			console.log("Applied Filters:", categories);
			// const leadsToFilter =
			// 	selectedTabIndex === 3
			// 		? starLeadsData
			// 		: selectedTabIndex === 8
			// 		? reminderLeadsData
			// 		: filteredData;
			const newFilteredLeads = await this.applyFilters(categories);
			const secretUserLeadsFilter =
				secretUserLeadFilter(newFilteredLeads);
			console.log("newFilteredLeads", secretUserLeadsFilter);
			this.setState(
				{
					filteredLeadsData: secretUserLeadsFilter,
					filterModal: false,
					renderFilter: true,
				},
				() => {
					const subCategoriesCount =
						this.countSubCategories(categories);
					console.log("Total subCategories:", subCategoriesCount);
					this.setFilterCount(subCategoriesCount);
				}
			);
		}
		// this.handleCloseModal();
	};

	render() {
		const subCategoriesCount = this.state.filtersCount || 0;

		if (
			this.state.users === null ||
			this.state.fetchingLeads ||
			// (this.state.filteredData.length <= 0 &&
			// 	this.state.allLeads.length <= 0) ||
			this.state.columnsOrder.length <= 0
			// ||
			// this.state.analyticsData.length <= 0
		) {
			return (
				<div style={{}}>
					<div>
						<LoaderFile />
					</div>
				</div>
			);
		}

		let showANL = false;
		let showSM = false;
		if (localStorage.getItem("permissions") != null) {
			showANL = localStorage
				.getItem("permissions")
				.toLowerCase()
				.includes("anl");
			showSM = localStorage
				.getItem("permissions")
				.toLowerCase()
				.includes("sm");
		}
		return (
			<div style={{ backgroundColor: "white" }}>
				{this.state.showProgressIndicator ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							height: "100%",
							position: "absolute",
							opacity: 0.7,
							backgroundColor: "white",
							zIndex: 9999,
						}}
					>
						<Loader
							type="Oval"
							color="#0080BF"
							height={80}
							width={80}
						/>
					</div>
				) : null}
				{this.state.renderFilter ? (
					<FilteredDataHeader
						filterData={this.state.filteredLeadsData}
						filteredLeadsData={this.state.filteredLeadsData}
						handleClearFilters={this.handleClearFilters}
						CSVFileName={this.state.CSVFileName}
						exportableData={this.state.exportableData}
						allowToUpdate={this.allowToUpdate}
						selectRowByAssignee={this.selectRowByAssignee}
						onCSVGenerate={this.onCSVGenerate}
					/>
				) :
				//ma 
				this.state.showSearchHeader ? (
					<SearchHeader
					clearSearchHeader={this.clearSearchHeader}
					/>
				): (
					// <>
					// 	<Row
					// 		// id="headerRow"
					// 		ref={this.headerRow}
					// 		onClick={() => {
					// 			this.allowToUpdate();
					// 			this.selectRowByAssignee(null);
					// 		}}
					// 		className="header"
					// 		style={{
					// 			minHeight: 60,
					// 		}}
					// 	>
					// 		<Col
					// 			md={4}
					// 			style={{
					// 				display: "flex",
					// 				flexDirection: "row",
					// 				alignItems: "center",
					// 				justifyContent: "space-between",
					// 				backgroundColor: "gray",
					// 				paddingLeft: "10rem",
					// 			}}
					// 		>
					// 			<p
					// 				style={{
					// 					fontSize: "17px",
					// 					color: "#000000",
					// 				}}
					// 			>
					// 				Filtered Data
					// 			</p>
					// 			<span
					// 				style={{
					// 					fontFamily: "SF Pro Display Semibold",
					// 					fontSize: "17px",
					// 					// color: "#000000",
					// 					color: "#6C6C6C",
					// 				}}
					// 			>
					// 				{this.state.filteredLeadsData.length}(Result
					// 				Found)
					// 			</span>

					// 			<span
					// 				onClick={this.exportLeadCSV}
					// 				style={{
					// 					border: "1px solid #4256D0",
					// 					display: "flex",
					// 					flexDirection: "row",
					// 					padding: "5px 10px",
					// 					borderRadius: 2,
					// 				}}
					// 			>
					// 				<img
					// 					style={{ height: 18, width: 18 }}
					// 					src={csvExportIcon}
					// 				/>
					// 				<p
					// 					style={{
					// 						fontSize: "12px",
					// 						color: "#4256D0",
					// 					}}
					// 				>
					// 					Export CSV
					// 				</p>
					// 				<img
					// 					style={{ height: 15, width: 15 }}
					// 					src={csvDownloadIcon}
					// 				/>
					// 			</span>
					// 			<CSVLink
					// 				data={this.state.exportableData}
					// 				filename={this.state.CSVFileName}
					// 				className="hidden"
					// 				ref={this.csvLinkEl}
					// 				target="_blank"
					// 			/>
					// 		</Col>
					// 		<Col
					// 			md={6}
					// 			style={{
					// 				display: "flex",
					// 				flexDirection: "row",
					// 				alignItems: "center",
					// 				backgroundColor: "green",
					// 			}}
					// 		></Col>{" "}
					// 		<Col
					// 			md={2}
					// 			style={{
					// 				display: "flex",
					// 				flexDirection: "row",
					// 				alignItems: "center",
					// 			}}
					// 		>
					// 			<span
					// 				onClick={this.handleClearFilters}
					// 				style={{
					// 					width: "11rem",
					// 					display: "flex",
					// 					flexDirection: "row",
					// 					alignItems: "center",
					// 					justifyContent: "space-between",
					// 					borderBottom: "1px solid #828282",
					// 				}}
					// 			>
					// 				<img
					// 					style={{
					// 						height: 15,
					// 						width: 15,
					// 					}}
					// 					src={crossIconX}
					// 				/>
					// 				<span
					// 					style={{
					// 						fontSize: "14px",
					// 						fontWeight: 500,
					// 						color: "#6C6C6C",
					// 					}}
					// 				>
					// 					Clear Results
					// 				</span>
					// 			</span>
					// 		</Col>{" "}
					// 	</Row>
					// </>
					<>
						{!this.state.headerCollapse &&
						!this.state.renderFilter ? (
							<Row
								// id="headerRow"
								ref={this.headerRow}
								onClick={() => {
									this.allowToUpdate();
									this.selectRowByAssignee(null);
								}}
								className="header"
								style={{
									minHeight: 60,
								}}
							>
								<Col
									md={3}
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<span
										style={{
											position: "absolute",
											bottom: 0,
										}}
									>
										<button
											style={{
												backgroundImage: `url(${dropdownIcon})`,
												width: 10,
												height: 10,
												marginTop: 5,
												border: "none",
												transform: "rotate(180deg)",
												backgroundRepeat: "no-repeat",
												backgroundColor: "transparent",
											}}
											onClick={() => {
												this.headerCollapseButtonClick();
											}}
										></button>
									</span>
									<button
										style={{
											width: 23,
											height: 29,
											marginLeft: "5%",
											marginBottom: "1%",
											backgroundImage: `url(${logoIcon})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "contain",
											border: "none",
											backgroundColor: "transparent",
											cursor: "pointer",
										}}
									></button>
									<span
										style={{
											marginLeft: "3%",
											marginTop: "8px",
											paddingTop: "3px",
											fontFamily:
												"SF Pro Display Semibold",
											fontSize: "17px",
											// color: "#000000",
											color: "#4256D0",
										}}
									>
										Lead System
									</span>
								</Col>
								{this.generateTabView()}
								{/* <Col
              md={1}
              style={{
                display: "flex",
                flexDirection: "row",
                color: "#8592AD",
                maxHeight: 70,
                padding: 0,
              }}
            >
              <div
                style={{}}
                className={
                  this.state.selectedTabIndex === 2
                    ? "tabElement active-tab"
                    : "tabElement"
                }
                onClick={() => {
                  this.analyticsTabClick();
                }}
              >
                <button
                  style={{
                    width: 30,
                    height: 18,
                    marginTop: this.state.selectedTabIndex === 2 ? 0 : 5,
                    backgroundImage: `url(${
                      this.state.selectedTabIndex === 2 ? analyticsActiveIcon : analyticsIcon
                    })`,
                  }}
                ></button>
                <span
                  style={{
                    marginTop: this.state.selectedTabIndex === 2 ? 1 : 5,
                    marginLeft: -12,
                    fontFamily: "SF Pro Display Semibold",
                    color: this.state.selectedTabIndex === 2 ? '#0a84fe' : '#000000',
                  }}
                >
                  Analytics
                </span>
              </div>
             
            </Col> */}
								<Col
									md={1}
									style={{
										display: "flex",
										flexDirection: "row",
										color: "#8592AD",
										maxHeight: 70,
										padding: 0,
									}}
								>
									<div
										style={{}}
										className={
											this.state.selectedTabIndex === 3
												? "tabElement active-tab"
												: "tabElement"
										}
										onClick={() => {
											this.starTabClick();
										}}
									>
										<button
											style={{
												width: 30,
												height: 20,
												marginTop:
													this.state
														.selectedTabIndex === 3
														? 0
														: 5,
												backgroundImage: `url(${
													// this.state.selectedTabIndex === 3 ? unstarIcon : starIcon
													this.state
														.selectedTabIndex === 3
														? hotlistIconSelected
														: hotlistIcon
												})`,
											}}
										></button>
										<span
											style={{
												marginTop:
													this.state
														.selectedTabIndex === 3
														? 1
														: 5,
												marginLeft: -10,
												fontFamily:
													"SF Pro Display Semibold",
												color:
													this.state
														.selectedTabIndex === 3
														? "#4256D0"
														: "#000000",
											}}
										>
											Hotlist
										</span>
									</div>
								</Col>

								<Col
									md={1}
									style={{
										display: "flex",
										flexDirection: "row",
										color: "#8592AD",
										maxHeight: 70,
										padding: 0,
									}}
								>
									<div
										style={{}}
										className={
											this.state.selectedTabIndex === 8
												? "tabElement active-tab"
												: "tabElement"
										}
										onClick={() => {
											this.reminderTabClick();
										}}
									>
										<button
											style={{
												width: 30,
												height: 20,
												marginTop:
													this.state
														.selectedTabIndex === 8
														? 0
														: 5,
												backgroundImage: `url(${
													// this.state.selectedTabIndex === 3 ? unstarIcon : starIcon
													this.state
														.selectedTabIndex === 8
														? reminderSetIcon
														: reminderIcon
												})`,
											}}
										></button>
										<span
											style={{
												marginTop:
													this.state
														.selectedTabIndex === 8
														? 1
														: 5,
												marginLeft: -10,
												fontFamily:
													"SF Pro Display Semibold",
												color:
													this.state
														.selectedTabIndex === 8
														? "#4256D0"
														: "#000000",
											}}
										>
											Reminders
										</span>
									</div>
								</Col>
								{/* ------------- Templates Tab------------- */}
								<Col
									md={1}
									style={{
										display:
											localStorage.getItem(
												"secretUserEmail"
											) === localStorage.getItem("email")
												? "none"
												: "flex",
										flexDirection: "row",
										color: "#8592AD",
										maxHeight: 70,
										padding: 0,
									}}
								>
									<div
										className={
											this.state.selectedTabIndex === 7
												? "tabElement active-tab"
												: "tabElement"
										}
										onClick={() => {
											this.templateTabClick();
										}}
									>
										<button
											style={{
												width: 30,
												height: 20,
												marginTop:
													this.state
														.selectedTabIndex === 7
														? 0
														: 5,
												backgroundImage: `url(${
													// templateIcon
													this.state
														.selectedTabIndex === 7
														? templateActiveIcon
														: templateIcon
												})`,
											}}
										></button>
										<span
											style={{
												marginTop:
													this.state
														.selectedTabIndex === 7
														? 1
														: 5,
												marginLeft: -10,
												fontFamily:
													"SF Pro Display Semibold",
												color:
													this.state
														.selectedTabIndex === 7
														? "#4256D0"
														: "#000000", //old #0a84fe
											}}
										>
											Templates
										</span>
									</div>
								</Col>
								<Col
									md={2}
									style={{
										padding: "0px 0px 0px 2%",
										display: "flex",
										justifyContent: "end",
										alignItems: "center",
									}}
								>
									{/* <input
                ref={(ref) => (this.searchFieldRef = ref) && ref.focus()}
                type='search'
                placeholder='Spotlight Search'
                className='search-bar'
                id='search-bar'
                value={this.state.searchInputVal}
                style={{
                  width: "90%",
                  height: "58%",
                  // marginRight: "-10%",
                  paddingRight: "50px",
                  paddingLeft: "8px",
                  fontSize: "16px",
                  borderRadius: "7px",
                  border: "solid 2px #9399A4",
                  position: "absolute",
                  display:
                    this.state.displaySearchBar == true &&
                    this.state.selectedTabIndex === 0
                      ? "block"
                      : "none",
                }}
                onChange={(e) => {
                  this.setState({ searchInputVal: e.target.value });
                  this.LeadsScreenRef.searchLead(e.target.value);
                }}
              ></input> */}
									<button
										style={{
											width: 18,
											height: 10,
											marginLeft: 5,
											zIndex: 1,
											// backgroundImage: `url(${
											//   this.state.displaySearchBar == true ? closeIcon : ""
											// })`,
											// backgroundImage: `url(${searchIcon})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "contain",
											border: "none",
											backgroundColor: "transparent",
											cursor: "pointer",
											display:
												this.state.selectedTabIndex ===
												0
													? "block"
													: "none",
										}}
										onClick={() => {
											this.clearSearchBar();
										}}
									></button>
									<button
										style={{
											width: 20,
											height: 18,
											zIndex: 1,
											marginRight: 5,
											marginLeft: 4,
											backgroundImage: `url(${
												this.state.displaySearchBar ==
												true
													? searchActiveIcon
													: searchIcon
											})`,
											// backgroundImage: `url(${searchIcon})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "contain",
											border: "none",
											backgroundColor: "transparent",
											cursor: "pointer",
											display:
												this.state.selectedTabIndex ===
												0
													? "block"
													: "none",
										}}
										onClick={() => {
											this.toggleSearchBar();
										}}
									></button>
								</Col>
								<Col
									md={3}
									style={{
										alignItems: "center",
										display: "inline-block",
										padding: "0",
									}}
								>
									<div
										style={{
											width: "60%",
											display: "inline-block",
											padding: "0",
											textAlign: "center",
										}}
									>
										<span
											className="leads-source-icon"
											style={{
												marginRight: "11%",
											}}
										>
											{showSM && (
												<SourceMapping
													showAlert={this.showAlert}
												/>
											)}
										</span>
										<span
											className="leads-icon"
											style={{
												marginRight: "11%",
											}}
										>
											{showANL && (
												<AddNewLeadForm
													leadWebSourceData={
														this.state.locations
													}
												/>
											)}
										</span>
										<span
											className="users-icon"
											style={{
												marginRight: "11%",
											}}
										>
											<button
												style={{
													width: 18,
													height: 19,
													marginTop: 5,
													backgroundImage: `url(${
														this.state
															.selectedTabIndex ===
														1
															? usersActiveIcon
															: usersIcon
													})`,
													backgroundRepeat:
														"no-repeat",
													backgroundSize: "contain",
													border: "none",
													backgroundColor:
														"transparent",
													cursor: "pointer",
												}}
												onClick={() => {
													this.userTabBtnClick();
												}}
											></button>
											<span className="tooltip-text users-tooltip">
												Users
											</span>
										</span>
										<span
											className="form-icon"
											style={{
												marginRight: "11%",
											}}
										>
											<button
												style={{
													width: 20,
													height: 20,
													marginTop: 5,
													backgroundImage: `url(${docIcon})`,
													backgroundRepeat:
														"no-repeat",
													backgroundSize: "contain",
													border: "none",
													backgroundColor:
														"transparent",
													cursor: "pointer",
												}}
												onClick={() => {
													this.openOmegaForm();
												}}
											></button>
											<span className="tooltip-text form-tooltip">
												Open Form
											</span>
										</span>
										{/* <span>
                  <button
                    style={{
                      width: 16,
                      height: 19,
                      marginTop: 5,
                      backgroundImage: `url(${toggleNavbarIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.headerCollapseButtonClick();
                    }}
                  ></button>
                </span> */}
									</div>
									<div
										className="logout"
										style={{
											width: "40%",
											height: "100%",
											display: "inline-block",
											padding: "0",
										}}
									>
										<div
											className="tabElement"
											style={{
												justifyContent: "center",
											}}
											onClick={() => {
												this.onLogoutClick();
											}}
										>
											<button
												style={{
													width: 30,
													height: 20,
													marginTop: 5,
													backgroundImage: `url(${logoutIcon})`,
												}}
											></button>
											<span
												style={{
													marginTop: 9,
													marginLeft: -12,
													fontWeight: "700",
													fontFamily:
														"SF Pro Display Medium",
												}}
											>
												Log Out
											</span>
										</div>
									</div>
									<div
										style={{
											position: "absolute",
											right: 28,
											top: 0,
										}}
									>
										<span>V {this.state.version}</span>{" "}
									</div>
								</Col>
								{/* <Col md={1} style={{ maxWidth: 70, maxHeight: 70 }}> */}
								{/* <span style={{ position: "absolute", bottom: 0 }}>
                <button
                  style={{
                    backgroundImage: `url(${dropdownIcon})`,
                    width: 10,
                    height: 10,
                    marginTop: 5,
                    border: "none",
                    transform: "rotate(180deg)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => {
                    this.headerCollapseButtonClick();
                  }}
                ></button>
              </span> */}
								{/* </Col> */}
							</Row>
						) : (
							<Row
								style={{ height: 10 }}
								onClick={() => {
									this.headerCollapseButtonClick();
								}}
							>
								<Col md={1} style={{ marginRight: -60 }}>
									<span
										style={{ position: "absolute", top: 0 }}
									>
										<button
											style={{
												backgroundImage: `url(${dropdownIcon})`,
												width: 10,
												height: 10,
												border: "none",
												backgroundRepeat: "no-repeat",
												backgroundColor: "transparent",
											}}
											onClick={() => {
												this.headerCollapseButtonClick();
											}}
										></button>
									</span>
								</Col>
							</Row>
						)}
					</>
				)}
				<div
					className="alert alert-danger alert-dismissible"
					style={{
						display: this.state.showProgressAlert ? "flex" : "none",
						width: "100%",
						height: 41,
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 9999,
						justifyContent: "center",
						fontSize: "medium",
						background: "#4256d0",
						borderColor: "#4256d0",
						color: "white",
						opacity: "98%",
					}}
				>
					<span
						onClick={this.onClickCloseProgressAlert}
						className="close"
						aria-label="close"
					>
						&times;
					</span>
					{this.state.alertMessage}.
					<Loader
						style={{ marginLeft: "5px", marginTop: "3px" }}
						type="Oval"
						color="white"
						height={20}
						width={20}
					/>
				</div>
				{/* ////// */}
				<div
					className="alert alert-danger alert-dismissible"
					style={{
						display: this.state.showAlert ? "flex" : "none",
						width: "100%",
						height: 41,
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 9999,
						justifyContent: "center",
						fontSize: "medium",
						background: "#4256d0",
						borderColor: "#4256d0",
						color: "white",
						opacity: "98%",
					}}
				>
					<span
						onClick={this.onClickCloseAlert}
						className="close"
						aria-label="close"
					>
						&times;
					</span>
					{this.state.alertMessage}.
				</div>
				{this.state.selectedTabIndex != 1 &&
				this.state.selectedTabIndex != 6 &&
				this.state.selectedTabIndex != 7 &&
				this.state.selectedTabIndex != 3 &&
				this.state.selectedTabIndex != 8 &&
				this.state.selectedTabIndex != 2 ? (
					<LeadsScreen //OptimisedTable //
						// innerRef={(ref) => (this.LeadsScreenRef = ref)}
						// ref={this.setLeadsScreenRef()}
						// ref={this.LeadsScreenRef}
						ref={(ref) => (this.LeadsScreenRef = ref)}
						today={this.state.todayDate}
						renderFilter={this.state.renderFilter}
						// key={this.state.itemskey}
						pageSize={this.state.pageSize}
						selectedCol={this.state.selectedTabIndex}
						changePageSize={this.changePageSize}
						showProgressAlert={this.showProgressAlert}
						refetchLeads={this.refetchLeads}
						allowToUpdate={this.allowToUpdate}
						users={this.state.allSystemUsers}
						selectedColumns={
							this.state.displayableColumns.find(
								(e) => e.tabIndex == this.state.selectedTabIndex
							).selectedColumns
						}
						leads={secretUserLeadFilter(
							this.state.renderFilter
								? this.state.filteredLeadsData
								:(this.state.displaySearchBar&&this.state.searchInputVal!="")?
                                  this.state.searchedLeads
								: this.state.filteredData
						)}
						tempData={this.state.tempData}
						searchInputVal={this.state.searchInputVal}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						confirmReviewAction={this.confirmReviewAction}
						updateData={this.updateData}
						allUsers={this.state.users}
						updateSearchData={this.updateSearchData}
						updateDataByID={this.updateDataByID}
						updateLeadAfterAutoFollowup={
							this.UpdateLeadAfterAutoFollowUp
						}
						deleteLead={this.onDeleteLead}
						setLoadingStatus={this.setLoadingStatus}
						isDataUpdated={this.isDataUpdated}
						tableHeight={this.state.innerAvailableHeight}
						showAlert={this.showAlert}
						getRowColorByAssignee={this.getRowColorByAssignee}
						highlightColor={this.state.highlightColor}
						columnsSize={this.state.columnsSize}
						SelectedUser={this.state.SelectedUser}
						selectRowByAssignee={this.selectRowByAssignee}
						columnsOrder={
							localStorage.getItem("columnsOrder") != null
								? localStorage.getItem("columnsOrder")
								: JSON.stringify(this.state.columnsOrder)
						}
						onPressContactLead={() =>
							this.setState({ showProgressIndicator: true })
						}
						UpdateLeadAfterAssigningSequence={
							this.UpdateLeadAfterAssigningSequence
						}
					/>
				) : this.state.selectedTabIndex === 1 ? (
					<UsersScreen
						onExportCustomersClick={this.onExportCustomersClick}
						ref={(ref) => (this.userScreenRef = ref)}
						allowToUpdate={this.allowToUpdate}
						users={this.state.allUsersData}
						OnUpdateUserColor={this.OnUpdateUserColor}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						tableHeight={this.state.innerAvailableHeight}
						updateUser={this.OnSaveNewUser}
						showAlert={this.showAlert}
						onAddNewUserButtonClick={this.onAddNewUserButtonClick}
						deleteUser={this.OnDeleteUser}
						onEditClicked={this.OnEditClicked}
					/>
				) : this.state.selectedTabIndex === 2 ? (
					<AnalyticsScreen
						onExportCustomersClick={this.onExportCustomersClick}
						ref={(ref) => (this.userScreenRef = ref)}
						allowToUpdate={this.allowToUpdate}
						users={this.state.allUsersData}
						OnUpdateUserColor={this.OnUpdateUserColor}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						tableHeight={this.state.innerAvailableHeight}
						updateUser={this.OnSaveNewUser}
						showAlert={this.showAlert}
						onAddNewUserButtonClick={this.onAddNewUserButtonClick}
						deleteUser={this.OnDeleteUser}
						onEditClicked={this.OnEditClicked}
						analyticsData={this.state.analyticsData}
						leadWebSourceData={this.state.locations}
					/>
				) : this.state.selectedTabIndex === 3 ? (
					<StarScreen
						ref={(ref) => (this.StarScreenRef = ref)}
						today={this.state.todayDate}
						// key={this.state.itemskey}
						pageSize={this.state.pageSize}
						changePageSize={this.changePageSize}
						refetchLeads={this.refetchLeads}
						allowToUpdate={this.allowToUpdate}
						users={this.state.allSystemUsers}
						selectedColumns={
							this.state.displayableColumns.find((e) => {
								return (
									e.tabIndex == this.state.selectedTabIndex
								);
							}).selectedColumns
						}
						selectedCol={this.state.selectedTabIndex}
						leads={
							this.state.renderFilter
								? this.state.filteredLeadsData
								: this.state.starLeadsData
						}
						// leads={this.state.starLeadsData}
						tempData={this.state.tempData}
						searchInputVal={this.state.searchInputVal}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						confirmReviewAction={this.confirmReviewAction}
						updateData={this.updateData}
						updateSearchData={this.updateSearchData}
						updateDataByID={this.updateDataByID}
						updateLeadAfterAutoFollowup={
							this.UpdateLeadAfterAutoFollowUp
						}
						deleteLead={this.onDeleteLead}
						setLoadingStatus={this.setLoadingStatus}
						isDataUpdated={this.isDataUpdated}
						tableHeight={this.state.innerAvailableHeight}
						showAlert={this.showAlert}
						showProgressAlert={this.showProgressAlert}
						getRowColorByAssignee={this.getRowColorByAssignee}
						UpdateLeadAfterAssigningSequence={
							this.UpdateLeadAfterAssigningSequence
						}
						highlightColor={this.state.highlightColor}
						columnsSize={this.state.columnsSize}
						SelectedUser={this.state.SelectedUser}
						selectRowByAssignee={this.selectRowByAssignee}
						columnsOrder={
							localStorage.getItem("columnsOrder") != null
								? localStorage.getItem("columnsOrder")
								: JSON.stringify(this.state.columnsOrder)
						}
						onPressContactLead={() =>
							this.setState({ showProgressIndicator: true })
						}
					/>
				) : this.state.selectedTabIndex === 8 ? (
					<ReminderScreen
						ref={(ref) => (this.ReminderScreenRef = ref)}
						today={this.state.todayDate}
						// key={this.state.itemskey}
						pageSize={this.state.pageSize}
						changePageSize={this.changePageSize}
						refetchLeads={this.refetchLeads}
						allowToUpdate={this.allowToUpdate}
						users={this.state.allSystemUsers}
						selectedColumns={
							this.state.displayableColumns.find((e) => {
								return (
									e.tabIndex == this.state.selectedTabIndex
								);
							}).selectedColumns
						}
						// selectedColumns={
						// 	this.state.displayableColumns.find(
						// 		(e) => e.tabIndex == this.state.selectedTabIndex
						// 	).selectedColumns
						// }
						selectedCol={this.state.selectedTabIndex}
						leads={
							this.state.renderFilter
								? this.state.filteredLeadsData
								: this.state.reminderLeadsData
						}
						// leads={this.state.reminderLeadsData}
						tempData={this.state.tempData}
						searchInputVal={this.state.searchInputVal}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						confirmReviewAction={this.confirmReviewAction}
						updateData={this.updateData}
						updateSearchData={this.updateSearchData}
						updateDataByID={this.updateDataByID}
						updateLeadAfterAutoFollowup={
							this.UpdateLeadAfterAutoFollowUp
						}
						deleteLead={this.onDeleteLead}
						setLoadingStatus={this.setLoadingStatus}
						isDataUpdated={this.isDataUpdated}
						tableHeight={this.state.innerAvailableHeight}
						showAlert={this.showAlert}
						getRowColorByAssignee={this.getRowColorByAssignee}
						UpdateLeadAfterAssigningSequence={
							this.UpdateLeadAfterAssigningSequence
						}
						highlightColor={this.state.highlightColor}
						columnsSize={this.state.columnsSize}
						SelectedUser={this.state.SelectedUser}
						selectRowByAssignee={this.selectRowByAssignee}
						columnsOrder={
							localStorage.getItem("columnsOrder") != null
								? localStorage.getItem("columnsOrder")
								: JSON.stringify(this.state.columnsOrder)
						}
						onPressContactLead={() =>
							this.setState({ showProgressIndicator: true })
						}
					/>
				) : this.state.selectedTabIndex === 6 ? (
					<ColumnsChecklist
						onUpdateColumnSelection={this.onUpdateColumnSelection}
						displayableColumns={this.state.displayableColumns}
						allAvailableColumns={this.allAvailableColumns}
					/>
				) : this.state.selectedTabIndex === 7 ? (
					<Templates
						ref={(ref) => (this.StarScreenRef = ref)}
						headerCollapse={this.state.headerCollapse}
						// key={this.state.itemskey}
						pageSize={this.state.pageSize}
						changePageSize={this.changePageSize}
						refetchLeads={this.refetchLeads}
						allowToUpdate={this.allowToUpdate}
						users={this.state.users}
						selectedCol={this.state.selectedTabIndex}
						leads={this.state.starLeadsData}
						tempData={this.state.tempData}
						searchInputVal={this.state.searchInputVal}
						fetchMoreData={this.fetchMoreData}
						confirmAction={this.confirmAction}
						confirmReviewAction={this.confirmReviewAction}
						updateData={this.updateData}
						updateSearchData={this.updateSearchData}
						updateDataByID={this.updateDataByID}
						updateLeadAfterAutoFollowup={
							this.UpdateLeadAfterAutoFollowUp
						}
						deleteLead={this.onDeleteLead}
						setLoadingStatus={this.setLoadingStatus}
						isDataUpdated={this.isDataUpdated}
						tableHeight={this.state.innerAvailableHeight}
						showAlert={this.showAlert}
						getRowColorByAssignee={this.getRowColorByAssignee}
						highlightColor={this.state.highlightColor}
						columnsSize={this.state.columnsSize}
						SelectedUser={this.state.SelectedUser}
						selectRowByAssignee={this.selectRowByAssignee}
						columnsOrder={
							localStorage.getItem("columnsOrder") != null
								? localStorage.getItem("columnsOrder")
								: JSON.stringify(this.state.columnsOrder)
						}
						onPressContactLead={() =>
							this.setState({ showProgressIndicator: true })
						}
					/>
				) : null}
				<div
					className="ExportElement"
					style={{
						// borderTop:'2px solid #f7f7f7',
						position: "absolute",
						bottom: 30,
						height: 0,
						left: "3%",
						width: "15%",
						zIndex: 1,
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",
						display:
							this.state.selectedTabIndex != 1 &&
							this.state.selectedTabIndex != 2 &&
							this.state.selectedTabIndex != 6 &&
							this.state.selectedTabIndex != 7
								? "flex"
								: "none",
					}}
				>
					<button
						onClick={this.handleOpenFilterModal}
						style={{
							width: "8.3rem",
							height: 30,
							backgroundColor: "#0A84FE",
							color: "white",
							borderRadius: 5,
						}}
					>
						{`Filters${
							subCategoriesCount > 0
								? ` ${subCategoriesCount}`
								: ""
						}`}
					</button>
					{this.state.renderFilter && (
						<span
							onClick={this.handleClearFilters}
							style={{
								width: "12rem",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								borderBottom: "1px solid #828282",
								marginLeft: 5,
							}}
						>
							<img
								style={{
									height: 15,
									width: 15,
								}}
								src={crossIconX}
							/>
							<span
								style={{
									fontSize: "14px",
									fontWeight: 500,
									color: "#6C6C6C",
								}}
							>
								Clear All Filters
							</span>
						</span>
					)}
				</div>
				<div
					className="ExportElement"
					style={{
						position: "absolute",
						bottom: 30,
						height: 0,
						right: "1%",
						paddingRight: "5rem",
						zIndex: 1,
						flexDirection: "row",
						display:
							this.state.selectedTabIndex != 1 &&
							this.state.selectedTabIndex != 2 &&
							this.state.selectedTabIndex != 6 &&
							this.state.selectedTabIndex != 7
								? "flex"
								: "none",

						justifyContent: "flex-end",
					}}
				>
					<button
						onClick={() => {
							this.handleOpenLeadExportModal();
						}}
						style={{
							width: 30,
							height: 20,
							backgroundImage: `url(${exportDownloadIcon})`,
						}}
					></button>
					<span
						onClick={() => {
							this.handleOpenLeadExportModal();
						}}
						style={{
							color: "#0A84FE",
							fontSize: 12,
							fontFamily: "SF Pro Text Bold",
						}}
					>
						Export Data
					</span>

					{this.state.filterModal && (
						<FilterModal
							open={this.state.filterModal}
							onClose={this.handleCloseFilterModal}
							onApplyFilters={this.handleApplyFilters}
							users={this.state.allSystemUsers}
							showAlert={this.showAlert}
							loader={this.state.loader}
							// setFilterName={this.setFilterName}
							handleAddSubCategory={this.handleAddSubCategory}
							handleAddSavedFilter={this.handleAddSavedFilter}
							filterName={this.state.filterName}
							handleSubCategoryChange={
								this.handleSubCategoryChange
							}
							categories={this.state.categories}
							handleResetFilters={this.handleResetFilters}
							isFilterAdded={this.state.isFilterAdded}
						/>
					)}
				</div>{" "}
				{this.state.exportLeadModal && (
					<ExportLeadsModal
						open={this.state.exportLeadModal}
						handleClose={this.handleCloseLeadExportModal}
						showAlert={this.showAlert}
						filteredLeads={this.state.filteredLeadsData}
						// handleExport={handleExport}
					/>
				)}
				{this.state.showSnoozeModal && (
					<SnoozeModal
						show={this.state.showModal}
						onCancelEditModal={() => {
							this.setState({ showModal: false });
						}}
						onShowCustomPicker={() => {
							this.setState({ showCustomPicker: true });
						}}
						submitValue={this.submitValue}
					/>
				)}
				{
					//ma
					<SpotlightSearch
					    showSearchHeader={this.state.showSearchHeader}
						show={this.state.displaySearchBar}
						close={() => this.openSearchHeader()}
						searchValue={this.state.searchInputVal}
						searchFunc={() =>
							this.searchLead(
								this.state.searchInputVal
							)
						}
						clearValue={() => {
							this.setState({ searchInputVal: "" });
							// this.LeadsScreenRef.searchLead("");
						}}
						onChange={(e) => {
							console.log("Ref:");
							this.setState({ searchInputVal: e.target.value });
							this.searchLead(e.target.value);
						}}
					/>
				}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addUserData: (templatesData) => dispatch(addUserData(templatesData)),
		addSelectedSmsFilter: (templatesData) =>
			dispatch(addSelectedSmsFilter(templatesData)),
		addSelectedFilter: (templatesData) =>
			dispatch(addSelectedFilter(templatesData)),
	};
};

// export default App;
// export default ,connect(mapStateToProps, mapDispatchToProps)(App);
export default connect(null, mapDispatchToProps)(withRouter(App));

// export default connect(mapDispatchToProps)(App);
